import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import "./RefundPolicy.css"

const RefundPolicyPage = () => {
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const { slug } = useParams();
    const [selectedArtwork, setSelectedArtwork] = useState([]);

    useEffect(() => {
        window.scroll(0, 0);
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dynamic/page/${slug}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const jsonData = await response.json();
                setPageData(jsonData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        return () => {
            setPageData(null);
        };
    }, [slug]);

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const toggleMedia = () => {
        setSelectedArtwork(prevState => !prevState);
    };

    return (
        <div className="container">
            <div style={{ minHeight: "300px" }}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "300px" }}>
                        <Spinner animation="border" role="status" className='pt-4'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    pageData && (
                        <div>
                            <h1 className="title text-start pt-5">{pageData.title}</h1>
                            {pageData.media && (
                                <div className='pt-5'>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}/${pageData.media.link}`} alt={pageData.title} className="img-fluid" onClick={toggleMedia} />
                                </div>
                            )}
                            <div className="content text-start pt-3 pb-0" dangerouslySetInnerHTML={{ __html: pageData.content }} />
                            <div className='col-md-8'>
                                {pageData.faq.length > 0 && (
                                    <div className="faq text-start">
                                        <h2 className='dynamic-page-faq'>FAQ</h2>
                                        <ul className='dynamic-pages p-0'>
                                            {pageData.faq.map((item, index) => (
                                                <li key={index}>
                                                    <div className="faq-items mb-3 mt-4">
                                                        <div className="faq-title" onClick={() => toggleFAQ(index)}>
                                                            <div className="faq-ques">
                                                                <h5 className="faq-question mt-2">{item.question}</h5>
                                                            </div>
                                                            <span className="plus-icons">{expandedIndex === index ? "-" : "+"}</span>
                                                        </div>
                                                        <div className={expandedIndex === index ? "content1 show" : "content1"}>
                                                            <p className="faq-answer text-start px-3" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default RefundPolicyPage;
