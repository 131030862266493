import React from 'react';
import { Link } from 'react-router-dom';
import AddToCart from './AddToCart';

const ChooseSize = () => {
    const sizes = [
        { label: 'S', id: 'sizeInput1' },
        { label: 'M', id: 'sizeInput2' },
        { label: 'L', id: 'sizeInput3' },
        { label: 'XL', id: 'sizeInput4' },
        { label: '2XL', id: 'sizeInput5' },
        { label: '3XL', id: 'sizeInput6' },
        { label: '4XL', id: 'sizeInput7' }
    ];

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-4 mb-5 p-3' style={{ background: "#f6f6f6" }}>
                    <p className='text-start  m-0 '><b>Choose Size</b></p>
                    <div className='d-flex gap-1 mt-2'>
                        {sizes.map((size, index) => (
                            <div key={index}>
                                <label htmlFor={size.id} className='me-2'><b>{size.label}</b></label>
                                <input type="text" className="form-control me-2" id={size.id} placeholder="" />
                                <div className='' style={{ color: "#03406c" }}>
                                    <p className='p-0 mt-2' style={{ marginBottom: "0px" }}><b>$10.99</b></p>
                                    <p className='p-0 m-0'>999+</p>
                                    <p>In Stock</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className=''>
                        <AddToCart />
                    </div>
                    <div className='mt-3' style={{ color: "#366789" }}>
                        Pay in 4 interest-free installments for orders over <b >$50.00</b> with
                    </div>
                    <Link to="">
                        Learn more
                    </Link>
                    <p><b>And</b> now also offering  &  in checkout</p>
                </div>
            </div>
        </div>
    );
}

export default ChooseSize;
