import React, { useEffect, useState } from 'react';
import "./Register.css";
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF, FaGoogle, FaLinkedinIn, FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    window.scroll(0, 0);
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [first_name, setFirst_name] = useState("");
    const [first_nameError, setFirst_nameError] = useState("");
    const [last_name, setLast_name] = useState("");
    const [last_nameError, setLast_nameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [password_confirmation, setPassword_confirmation] = useState("");
    const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [touchedFields, setTouchedFields] = useState({});

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'password_confirmation') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const validateField = (fieldName, value) => {
        switch (fieldName) {
            case "email":
                if (!value) {
                    return "Email is a required field";
                } else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                    return "Please enter a valid email";
                }
                break;
            case "first_name":
                if (!value) {
                    return "First name is a required field";
                } else if (!/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{2,}$/.test(value)) {
                    return "Please enter a valid first name";
                }
                break;
            case "last_name":
                if (!value) {
                    return "Last name is a required field";
                } else if (!/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{2,}$/.test(value)) {
                    return "Please enter a valid last name";
                }
                break;
            case "password":
                if (!value) {
                    return "Password is a required field";
                } else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(value))) {
                    return "Please enter a valid password";
                }
                break;
            case "password_confirmation":
                if (!value) {
                    return "Password confirmation is a required field";
                } else if (value !== password) {
                    return "Password confirmation does not match";
                }
                break;
            default:
                return "";
        }
        return "";
    };

    const handleInputChange = (fieldName, value) => {
        setTouchedFields({ ...touchedFields, [fieldName]: true });
        switch (fieldName) {
            case "email":
                setEmail(value);
                setEmailError(validateField(fieldName, value));
                break;
            case "first_name":
                setFirst_name(value);
                setFirst_nameError(validateField(fieldName, value));
                break;
            case "last_name":
                setLast_name(value);
                setLast_nameError(validateField(fieldName, value));
                break;
            case "password":
                setPassword(value);
                setPasswordError(validateField(fieldName, value));
                break;
            case "password_confirmation":
                setPassword_confirmation(value);
                setPasswordConfirmationError(validateField(fieldName, value));
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate all fields
        const fieldsToValidate = ["email", "first_name", "last_name", "password", "password_confirmation"];
        let isFormValid = true;
        fieldsToValidate.forEach(fieldName => {
            const error = validateField(fieldName, fieldName === "password_confirmation" ? password_confirmation : eval(fieldName));
            if (error) {
                isFormValid = false;
                setTouchedFields({ ...touchedFields, [fieldName]: true });
                switch (fieldName) {
                    case "email":
                        setEmailError(error);
                        break;
                    case "first_name":
                        setFirst_nameError(error);
                        break;
                    case "last_name":
                        setLast_nameError(error);
                        break;
                    case "password":
                        setPasswordError(error);
                        break;
                    case "password_confirmation":
                        setPasswordConfirmationError(error);
                        break;
                    default:
                        break;
                }
            }
        });

        if (isFormValid) {
            try {
                const response = await fetch(

                    `${process.env.REACT_APP_API_URL}/registration`,

                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            first_name,
                            last_name,
                            email,
                            password,
                            password_confirmation,
                        }),
                    }
                );

                const responseData = await response.json();
                if (response.ok) {
                    toast.success(response.data.message);
                    navigate('/login');
                } else {
                    if (responseData && responseData.error) {
                        const errors = JSON.parse(responseData.error);
                        Object.keys(errors).forEach(errorField => {
                            switch (errorField) {
                                case "email":
                                    setEmailError(errors[errorField][0]);
                                    break;
                                case "first_name":
                                    setFirst_nameError(errors[errorField][0]);
                                    break;
                                case "last_name":
                                    setLast_nameError(errors[errorField][0]);
                                    break;
                                case "password":
                                    setPasswordError(errors[errorField][0]);
                                    break;
                                case "password_confirmation":
                                    setPasswordConfirmationError(errors[errorField][0]);
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                }
            } catch (error) {
                console.error('Error registering:', error);
            }
        }
    };




    const googleLogin = (e) => {
        e.preventDefault();
        setSubmitted(true);
        const popup = window.open(
            `${process.env.REACT_APP_API_URL}/social-login/google`,
            "Google Login",
            "width=600,height=400"
        );
        const closePopupListener = () => {
            window.removeEventListener('unload', closePopupListener);
            if (!popup.closed) {
                popup.close();
            }
        };
        window.addEventListener('unload', closePopupListener);
    };
    const handleSuccessfulLogin = () => {
        window.close();
        navigate('/');
    };

    window.addEventListener('message', (event) => {
        if (event.origin === "https://buit.in/anything-dashbaord") {
            const { token, user } = event.data;
            if (token && user) {
                localStorage.setItem("token", token);
                localStorage.setItem("user", JSON.stringify(user));
                toast.success("Successfully logged in with Google.");
                handleSuccessfulLogin();
            } else {
                toast.error("Failed to log in with Google.");
            }
        }
    });


    const facebookLogin = (e) => {
        e.preventDefault();
        setSubmitted(true);
        const popup = window.open(
            `${process.env.REACT_APP_API_URL}/social-login/facebook`,
            "Facebook Login",
            "width=600,height=400"
        );

        const popupTimer = setInterval(() => {
            if (popup.closed) {
                clearInterval(popupTimer);
                handlePopupClosed();
            }
        }, 1000);
    };

    const handlePopupClosed = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const user = urlParams.get('user');

        if (token && user) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            toast.success("Successfully logged in with Facebook.");
            navigate('/');
        } else {
            toast.error("Failed to log in with Facebook.");
        }
    };


    const linkedinLogin = (e) => {
        e.preventDefault();
        setSubmitted(true);

        const popup = window.open(`${process.env.REACT_APP_API_URL}/social-login/linkedin`, 'LinkedIn Login', 'width=600,height=400');


        const popupTimer = setInterval(() => {
            if (popup.closed) {
                clearInterval(popupTimer);
                handlePopupClosed1();
            }
        }, 1000);
    };


    const handlePopupClosed1 = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const user = urlParams.get('user');

        if (token && user) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            toast.success("Successfully logged in with LinkedIn.");
            navigate('/');
        } else {
            toast.error("Failed to log in with LinkedIn.");
        }
    };



    return (
        <div>
            <section className='register-img'>
                <Container>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs={12} md={6} lg={5} sm={12}>
                            <form className='register-form' onSubmit={handleSubmit}>
                                <h2 className=' mt-5 customer ' >Create an Account</h2>
                                <ToastContainer pauseOnHover />
                                <hr />

                                <div className="h_buttons">
                                    <div className="h_google_button" data="google" onClick={googleLogin}>
                                        <FaGoogle className="h_google_icon" />
                                        <span className="h_google_text h_google_full" >Google</span>
                                    </div>
                                    <div className="h_facebook_button" data="facebook" onClick={facebookLogin}>
                                        <FaFacebookF />
                                        <span className="h_facebook_text h_facebook_full">Facebook</span>
                                    </div>
                                    <div className="h_linkedin_button" data="linkedin" onClick={linkedinLogin}>
                                        <FaLinkedinIn />
                                        <span className="h_linkedin_text h_linkedin_full">LinkedIn</span>
                                    </div>
                                </div>

                                <div className="h_divider">OR</div>
                                <div className='email-label'>
                                    <label htmlFor="first_name">First Name</label>
                                    <input
                                        className='px-3'
                                        type="text"
                                        placeholder='First Name'
                                        onChange={(e) => handleInputChange("first_name", e.target.value)}
                                        value={first_name}
                                    />
                                    {touchedFields["first_name"] && <span className="text-danger">{first_nameError}</span>}
                                </div>
                                <div className='email-label'>
                                    <label htmlFor="last_name">Last Name</label>
                                    <input
                                        className='px-3'
                                        type="text"
                                        placeholder='Last Name'
                                        onChange={(e) => handleInputChange("last_name", e.target.value)}
                                        value={last_name}
                                    />
                                    {touchedFields["last_name"] && <span className="text-danger">{last_nameError}</span>}
                                </div>
                                <div className='email-label'>
                                    <label htmlFor="email">Email Name</label>
                                    <input
                                        className='px-3'
                                        type="text"
                                        placeholder='Your Email'
                                        onChange={(e) => handleInputChange("email", e.target.value)}
                                        value={email}
                                    />
                                    {touchedFields["email"] && <span className="text-danger">{emailError}</span>}
                                </div>

                                <div className='form-group'>
                                    <div className='email-label'>
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    <div className='password-input'>
                                        <input
                                            className='form-control px-3'
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            placeholder='Password'
                                            onChange={(e) => handleInputChange("password", e.target.value)}
                                            value={password}
                                        />
                                        <span className="password-toggle" onClick={() => togglePasswordVisibility('password')}>
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {touchedFields["password"] && <span className="text-danger">{passwordError}</span>}
                                </div>
                                <div className='email-label'>
                                    <label htmlFor="password_confirmation">Confirm Password</label>
                                    <div className='password-input'>
                                        <input
                                            className='px-3'
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder='Confirm Password'
                                            onChange={(e) => handleInputChange("password_confirmation", e.target.value)}
                                            value={password_confirmation}
                                        />
                                        <span className="password-toggle" onClick={() => togglePasswordVisibility('password_confirmation')}>
                                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {touchedFields["password_confirmation"] && <span className="text-danger">{passwordConfirmationError}</span>}
                                </div>
                                <div className='mt-3'>
                                    <button className='btn-register' variant="contained">Create</button>
                                </div>
                                <div className='alreay-account justify-content-center mt-4'>
                                    <p>Already have an account? <span><Link to="/login" className='text-cancel' style={{ color: '#b2b2b2' }}>Login</Link></span></p>
                                </div>
                            </form>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Register;
