import React from 'react';
import image1 from "../../assets/images/categroies.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Categories = () => {

    AOS.init();

    return (

        <div className='category'>
            <section className='Categories mt-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <p className='text-start popular-category'>Shop Popular Blank Categories</p>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-md-12' data-aos="fade-down">
                            <div className='d-flex flex-wrap justify-content-center gap-2' >
                                <div className='categroies' style={{ position: 'relative' }}>
                                    <div className='cardss category-cards'>
                                        <img src={image1} alt='image1' style={{ transition: 'transform 0.3s ease' }} />
                                    </div>
                                    <p className='cottons mt-3'>Blank T-Shirts</p>
                                </div>
                                <div className='categroies' style={{ position: 'relative' }}>
                                    <div className='cardss category-cards'>
                                        <img src={image1} alt='image2' style={{ transition: 'transform 0.3s ease' }} />
                                    </div>
                                    <p className='cottons mt-3'>Blank Hoodies</p>
                                </div>
                                <div className='categroies' style={{ position: 'relative' }}>
                                    <div className='cardss category-cards'>
                                        <img src={image1} alt='image3' style={{ transition: 'transform 0.3s ease' }} />
                                    </div>
                                    <p className='cottons mt-3'>Blank Sweatshirts</p>
                                </div>
                                <div className='categroies' style={{ position: 'relative' }}>
                                    <div className='cardss category-cards'>
                                        <img src={image1} alt='image1' style={{ transition: 'transform 0.3s ease' }} />
                                    </div>
                                    <p className='cottons mt-3'>Blank T-Shirts</p>
                                </div>
                                <div className='categroies' style={{ position: 'relative' }}>
                                    <div className='cardss category-cards'>
                                        <img src={image1} alt='image2' style={{ transition: 'transform 0.3s ease' }} />
                                    </div>
                                    <p className='cottons mt-3'>Blank Hoodies</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Categories;