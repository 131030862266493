import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ImageSlider from './ImageSlider';
import ProductDetails from './ProductDetails';
import BulkDiscount from './BulkDiscount';
import ProductDetails1 from './ProductDetails1';

const ProductSlider = ({ productData }) => {
    console.log(productData, "slider")
    return (
        <div className='container'>
            <Row className='py-5'>
                <Col md={6}>
                    <ImageSlider
                        media={productData.media}
                        gallery={productData.gallery}
                    />
                </Col>
                <Col md={6}>
                    <ProductDetails
                        productData={productData}

                    />
                    {/*  <ProductDetails1
                        price={productData.pricing.regular_price}
                        variations={productData.variations}
    /> */}
                </Col>
            </Row>
            <Row>
            </Row>
        </div>
    );
}

export default ProductSlider;
