
import React from 'react';

const BulkDiscount = () => {

    const discounts = [
        { cartTotal: '$150+', whitePrice: '$10.99', colorPrice: '$12.99' },
        { cartTotal: '$150+', whitePrice: '$10.99', colorPrice: '$12.99' },
        { cartTotal: '$150+', whitePrice: '$10.99', colorPrice: '$12.99' }
    ];

    return (
        <div>
            <h5 className='text-start bulk-discount'>Bulk Buyer Discount</h5>
            <div className='table-border1'>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Cart Total</th>
                            <th scope="col">White</th>
                            <th scope="col">Colors</th>
                        </tr>
                    </thead>
                    <tbody>

                        {discounts.map((discount, index) => (
                            <tr key={index}>
                                <th scope="row">{discount.cartTotal}</th>
                                <td>{discount.whitePrice} <sub>49% off</sub></td>
                                <td>{discount.colorPrice} <sub>49% off</sub></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p className='text-start px-3'>Prices are for sizes S - XL</p>
            </div>
        </div>
    );
}

export default BulkDiscount;
