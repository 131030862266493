

import React, { useState } from "react";
const ReedomAccordian = ({ title, children, isOpenByDefault }) => {
    const [isCollapsed, setIsCollapsed] = useState(!isOpenByDefault);

    const toggleAccordion = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="reedomfaq">
            <div className="accordion">
                <div className="accordion-header" onClick={toggleAccordion}>
                    <div className={`accordion-icon ${isCollapsed ? "collapsed" : ""}`}>
                        {isCollapsed ? "+" : "-"}
                    </div>
                    <div className="accordion-title">{title}</div>
                </div>
                {!isCollapsed && <div className="accordion-content">{children}</div>}
            </div>
        </div>
    );
};

export default ReedomAccordian;