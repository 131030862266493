import React, { useState } from 'react';
import VideoThumbnail from "../../assets/images/product3.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const MainData = ({ displayMode, products }) => {

    const [videoHovered, setVideoHovered] = useState(null);

    const handleVideoHover = (videoId, isHovered) => {
        if (isHovered) {
            setVideoHovered(videoId);
        } else {
            setVideoHovered(videoId);
        }
    };

    return (
        <div className='maindata'>
            <section className='main-data'>
                <div className='container'>
                    {displayMode === 'all' ? (
                        <div>
                            {products.map((item, index) => {
                                const product = item.product;
                                const pricing = product.pricing;
                                const media = product.media;
                                const video = product.video;
                                return (
                                    <div key={index} className="row mt-2 mb-3">
                                        <div className="col-md-4 mb-4">
                                            <div className="card product-card1 product-hovers">
                                                <div className='products-images1'>
                                                    {video ? (
                                                        <div
                                                            className="video-container position-relative"
                                                            onMouseEnter={() => handleVideoHover(video.link, true)}
                                                            onMouseLeave={() => handleVideoHover(video.link, false)}
                                                        >
                                                            {videoHovered === video.link ? (
                                                                <video width="250" height="250" controls>
                                                                    <source src={`https://buit.in/anything-dashbaord/storage/${video.link}`} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            ) : (
                                                                <>
                                                                    <img src={`https://buit.in/anything-dashbaord/storage/${media.link}`} alt='product' />
                                                                    <div className="play-icon">
                                                                        <FontAwesomeIcon icon={faPlayCircle} />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <img src={VideoThumbnail} alt='VideoThumbnail' />
                                                    )}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <div className='product-details1 mx-2'>
                                                <div className='icn-pro'>
                                                    <p className='product-name mt-3'>{product.title}</p>
                                                </div>
                                                <div className='stock-status mt-3'>
                                                    {pricing.stock_status === 1 ? (
                                                        <div className="in-stock">
                                                            <FontAwesomeIcon icon={faCheckCircle} className="in-stock-icon" />
                                                            <span>In Stock</span>
                                                        </div>
                                                    ) : (
                                                        <div className="out-of-stock">
                                                            <FontAwesomeIcon icon={faTimesCircle} className="out-of-stock-icon" />
                                                            <span>Out of Stock</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='product-price mt-3'>
                                                    <h5 className='mx-2'>${pricing.regular_price}</h5>
                                                </div>

                                                <button className="btn btn-primary hover-button order-now mt-2 mb-4">
                                                    <span className="btn-text">order-now</span>
                                                    <span className="btn-icon">&rarr;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="row mt-2 mb-3">
                            {products.map((item, index) => {
                                const product = item.product;
                                const pricing = product.pricing;
                                const media = product.media;
                                const video = product.video;
                                console.log(video, "video")
                                return (
                                    <div key={index} className="col-md-4 mb-4">
                                        <div className="card product-card1 product-hovers">
                                            <div className='products-images1'>
                                                {video ? (
                                                    <div
                                                        className="video-container position-relative"
                                                        onMouseEnter={() => handleVideoHover(video.link, true)}
                                                        onMouseLeave={() => handleVideoHover(video.link, false)}
                                                    >
                                                        {videoHovered === video.link ? (
                                                            <video width="250" height="250" controls>
                                                                <source src={`https://buit.in/anything-dashbaord/storage/${video.link}`} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            <>
                                                                <img src={`https://buit.in/anything-dashbaord/storage/${media.link}`} alt='product' />
                                                                <div className="play-icon">
                                                                    <FontAwesomeIcon icon={faPlayCircle} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <img src={VideoThumbnail} alt='VideoThumbnail' />
                                                )}
                                            </div>
                                            <div className='product-details1 mx-2'>
                                                <div className='icn-pro'>
                                                    <p className='text-center product-name mt-3'>{product.title}</p>
                                                </div>
                                                <div className='stock-status mt-3'>
                                                    {pricing.stock_status === 1 ? (
                                                        <div className="in-stock">
                                                            <FontAwesomeIcon icon={faCheckCircle} className="in-stock-icon" />
                                                            <span>In Stock</span>
                                                        </div>
                                                    ) : (
                                                        <div className="out-of-stock">
                                                            <FontAwesomeIcon icon={faTimesCircle} className="out-of-stock-icon" />
                                                            <span>Out of Stock</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='product-price mt-3'>
                                                    <h5 className='mx-2'>${pricing.regular_price}</h5>
                                                </div>
                                                <Link to={`/product-detail/${product.slug}`}>
                                                    <button className="btn btn-primary hover-button order-now mt-2 mb-4">
                                                        <span className="btn-text">order-now</span>
                                                        <span className="btn-icon">&rarr;</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div className='text-center load-more-product-btn'>
                        <button className="btn btn-primary hover-button load-more-products mt-3">
                            <span className="btn-text">Load More</span>
                            <span className="btn-icon">&rarr;</span>
                        </button>
                    </div>
                </div>
            </section >
        </div >
    );
}

export default MainData;
