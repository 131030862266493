import React from "react";
import "./contactstan.css";
const ContactStandard = () => {
  window.scroll(0, 0);
  return (
    <div className="ContactStandard">
      <div className="container-fluid">
        <div className="innercontent text-start">
          <h1>Content Standards</h1>
          <p>
            Ninja Transfers supports bringing groups and communities together
            using custom apparel. We appreciate the impact a unifying focal
            point can have on building relationships among people.
          </p>
          <p>
            We understand that people have diverse perspectives as communicated
            through the custom designs they create. However, we must balance our
            customers' goals and freedom of expression with the potential
            negative impact on others and the general public.
          </p>
          <p>
            We use the following standards to clearly communicate what Ninja
            Transfers will, may, or will not print when creating custom
            products.
          </p>

          <div className="redimg">
            <img
              src="https://cdn.shopify.com/s/files/1/0558/0265/8899/files/Light_480x480.png?v=1683233204"
              alt=""
            />
          </div>

          <h1>Stoplight Content Standards</h1>
          <p>
            Ninja Transfers uses a set of content standards to set clear
            expectations about what will be printed. We use a color-coded,
            stoplight system to identify the types of content that meet our
            standards:
          </p>
          <br />
          <p>
            If a design is found to be questionable, we’ll help you to create an
            alternative that meets these standards.
          </p>
          <p>
            Ninja Transfers content categories are listed below. These
            descriptions provide general guidance and are not intended to be
            exhaustive. The ultimate decision on design approval rests with our
            content standards team.
          </p>
          <h1>Red Light: Designs That Will Not Be Printed</h1>
          <p>
            Ninja Transfers will not print designs when we a reasonably
            confident that they:
          </p>
          <ul className="ul-content">
            <li>
              {" "}
              Violate the intellectual property rights of others (see
              intellectual property policy)
            </li>
            <li>Constitute libel, fraud, or other illegal activity</li>
            <li>Encourage violence or abuse against people or groups</li>
            <li>Constitute or include hate speech</li>
            <li>Encourage hate or harassment of private individuals</li>
            <li>Promote, feature, or encourage the use of hard drugs</li>
            <li>
              Feature explicit sexual content or nudity of a
              non-artistic/health/medical nature
            </li>
            <li>
              Include sexually suggestive images or text involving children
            </li>
            <li>
              Promote, feature, or encourage underage drinking, smoking, or
              marijuana use
            </li>
          </ul>
          <h1>Stoplight Content Standards</h1>
          <p>
            Ninja Transfers uses a set of content standards to set clear
            expectations about what will be printed. We use a color-coded,
            stoplight system to identify the types of content that meet our
            standards:
          </p>
          <br />
          <p>
            If a design is found to be questionable, we’ll help you to create an
            alternative that meets these standards.
          </p>
          <p>
            Ninja Transfers content categories are listed below. These
            descriptions provide general guidance and are not intended to be
            exhaustive. The ultimate decision on design approval rests with our
            content standards team.
          </p>
          <h1>Yellow Light: Designs That May Not Be Printed</h1>
          <p>
            Ninja Transfers may not print or may require changes to designs
            that:
          </p>
          <ul className="ul-content">
            <li>
              {" "}
              Violate the intellectual property rights of others (see
              intellectual property policy)
            </li>
            <li>Constitute libel, fraud, or other illegal activity</li>
            <li>Encourage violence or abuse against people or groups</li>
            <li>Constitute or include hate speech</li>
            <li>Encourage hate or harassment of private individuals</li>
            <li>Promote, feature, or encourage the use of hard drugs</li>
            <li>
              Feature explicit sexual content or nudity of a
              non-artistic/health/medical nature
            </li>
            <li>
              Include sexually suggestive images or text involving children
            </li>
            <li>
              Promote, feature, or encourage underage drinking, smoking, or
              marijuana use
            </li>
          </ul>
          <h1>Green Light: Designs That Will Be Printed</h1>
          <p>Ninja Transfers will print designs that:</p>
          <ul className="ul-content">
            <li>Use language and images for people of all ages</li>
            <li>Are unlikely to offend others</li>
            <li>
              Support intellectual property rights by using legally authorized
              content
            </li>
          </ul>
          <h1>Questions About Content Standards & Printing?</h1>
          <p>
            If you have any questions about your design, whether it meets our
            standards for printing or how it may need to be adjusted, please
            contact one of our ninjas using:
          </p>
          <ul className="ul-content">
            <li>Instant Message</li>
            <li>Phone (888) 356-3665</li>
            <li>Email service@nintransfers.com</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ContactStandard;
