

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import Heat from "../../assets/images/heat.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const videoId = '_mvkLpUM92A';

const Steps = () => {
    AOS.init();
    const [showVideo, setShowVideo] = useState(false);

    const toggleVideo = () => {
        setShowVideo(!showVideo);
    };

    return (
        <div className='step mt-5 mb-3'>
            <section className='step'>
                <div className='container'>
                    <div className='row'>
                        <h3 className='heat-pressing mb-5 mt-5'>Heat pressing instructions in 3 easy steps</h3>
                        <div className='col-md-4 transfering mt-3 mb-5' data-aos="flip-up">
                            <div className='heat-border-radius d-flex flex-column align-items-center justify-content-center'>
                                <div className='circle'>1</div>
                                <img src={Heat} alt='heat' />
                            </div>
                            <div className='heat-content'>
                                <h5 className='text-center mt-3 place'>Place</h5>
                                <p className='pro-position'>Position your DTF (Direct to Film) Transfer on top of your product</p>
                            </div>
                        </div>
                        <div className='col-md-4 transfering mt-3 mb-5' data-aos="flip-up">
                            <div className='heat-border-radius text-center'>
                                <div className='circle'>2</div>
                                <img src={Heat} alt='heat' />
                            </div>
                            <div className='heat-content'>
                                <h5 className='text-center mt-3 place'>Press</h5>
                                <p className='pro-position'>Press the transfer with heat and pressure</p>
                            </div>
                        </div>
                        <div className='col-md-4 transfering mt-3 mb-5' data-aos="flip-up" >
                            <div className='heat-border-radius text-center'>
                                <div className='circle'>3</div>
                                <img src={Heat} alt='heat' />
                            </div>
                            <div className='heat-content'>
                                <h5 className='text-center mt-3 place'>Peel</h5>
                                <p className='pro-position'>Peel off the transfer to reveal your design</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`step-video ${showVideo ? 'show-video' : ''}`}>
                <div className='container'>
                    <div className='row' data-aos="zoom-in">
                        <div className='step-banner position-relative'>
                            {showVideo && (
                                <div className='close-video' onClick={toggleVideo}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            )}
                            {!showVideo && (
                                <div className='play-icn' onClick={toggleVideo}>
                                    <div className='icon-play position-absolute top-50 start-50 translate-middle'>
                                        <FontAwesomeIcon icon={faPlay} />
                                    </div>
                                    <p className='transfer'>See a DTF Transfer in Action</p>
                                </div>
                            )}
                            {showVideo && (
                                <div className='video-container'>
                                    <iframe
                                        title='YouTube Video'
                                        width='100%'
                                        height='500'
                                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                                        frameBorder='0'
                                        allow='autoplay; encrypted-media'
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Steps;
