
import React, { useState } from 'react';
import Slider from 'react-slick';
import "./Productdetail.css";


const ImageSlider = ({ media, gallery }) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <div className='product-slider'>

            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                {Array.isArray(media) &&
                    media.map((item) => (

                        <img key={item.id} src={`${process.env.REACT_APP_IMAGE_URL}/${item.link}`} alt='product' />
                    ))
                }

                {Array.isArray(gallery) &&
                    gallery.map((item) => (

                        <img key={item.id} src={`${process.env.REACT_APP_IMAGE_URL}/${item.link}`} alt='product-detail-slider1' />
                    ))
                }
            </Slider>

            <Slider
                className='second-p-slider'
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={4}
                swipeToSlide={true}
                focusOnSelect={true}
            >
                {Array.isArray(gallery) &&
                    gallery.map((item) => (
                        <img key={item.id} src={`${process.env.REACT_APP_IMAGE_URL}/${item.link}`} alt='product-detail-slider' />

                    ))
                }
            </Slider>
        </div>
    );
}

export default ImageSlider;
