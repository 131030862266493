import React from 'react';
import StartedImg from "../../assets/images/started.png";
import StartedImg1 from "../../assets/images/started1.png";
import StartedImg2 from "../../assets/images/started2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Ninja from "../../assets/images/product-img.png";
import Ninja1 from "../../assets/images/product-img1.png";
import Ninja2 from "../../assets/images/product-img2.png";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Started = () => {
    AOS.init();
    return (
        <div className='started ' >
            <section className='review-section mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex transfing'>
                                <div> <h3 className='started text-start me-3'>Get Started</h3></div>
                                <div> <span className='line-vertical'></span></div>
                                <div>  <p className='started-review mx-3'>Select the DTF Transfer product the works best for you</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-3' >
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 mb-3' data-aos="fade-down">
                            <div className='started12 '>
                                <div className='started-img'>
                                    <img src={StartedImg} alt='Started' className='inner-img1' />
                                </div>
                                <div className='d-flex flex-column justify-content-between align-items-center mt-3'>
                                    <p className='text-center'>As Low As - $0.03 per square inch</p>
                                    <h5 className='text-center'>DTF Transfers By Size</h5>
                                </div>
                                <div className='started-btns mt-4'>
                                    <button className="btn btn-primary hover-button button--solid ">
                                        <span className="btn-text">Shop Transfers by Size</span>
                                        <span className="btn-icon">&rarr;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3' data-aos="fade-down">
                            <div className='started12 '>
                                <div className='started-img'>
                                    <img src={StartedImg} alt='Started' className='inner-img1' />
                                </div>
                                <div className='d-flex flex-column justify-content-between align-items-center mt-3'>
                                    <p className='text-center'>As Low As - $0.03 per square inch</p>
                                    <h5 className='text-center'>DTF Transfers By Size</h5>
                                </div>
                                <div className='started-btns mt-4' >
                                    <button className="btn btn-primary hover-button button--solid ">
                                        <span className="btn-text">Shop Transfers by Size</span>
                                        <span className="btn-icon">&rarr;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3' data-aos="fade-down">
                            <div className='started12 '>
                                <div className='started-img'>
                                    <img src={StartedImg} alt='Started' className='inner-img1' />
                                </div>
                                <div className='d-flex flex-column justify-content-between align-items-center mt-3'>
                                    <p className='text-center'>As Low As - $0.03 per square inch</p>
                                    <h5 className='text-center'>DTF Transfers By Size</h5>
                                </div>
                                <div className='started-btns mt-4'>
                                    <button className="btn btn-primary hover-button button--solid ">
                                        <span className="btn-text">Shop Transfers by Size</span>
                                        <span className="btn-icon">&rarr;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 mb-3' data-aos="fade-up">
                            <div className='products-imgaesss  position-relative' >
                                <img src={Ninja} alt='Ninja' />
                                <div className='ninja-text'>
                                    <h5 className='ninja-patches'>anythingApparel Patches</h5>
                                    <p className='custom-patches'>Shop custom patches with free, quick delivery. Use a Heat Press or iron for application. No art or setup fees.</p>
                                    <div className='ninja-btn'>
                                        <button className="btn btn-primary hover-button button--solid  ninja-shop-btn">
                                            <span className="btn-text">Shop now</span>
                                            <span className="btn-icon">&rarr;</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3' data-aos="fade-up">
                            <div className='products-imgaesss  position-relative' >
                                <img src={Ninja1} alt='Ninja' />
                                <div className='ninja-text'>
                                    <h5 className='ninja-patches'>anythingApparel Patches</h5>
                                    <p className='custom-patches'>Shop custom patches with free, quick delivery. Use a Heat Press or iron for application. No art or setup fees.</p>
                                    <div className='ninja-btn'>
                                        <button className="btn btn-primary hover-button button--solid  ninja-shop-btn">
                                            <span className="btn-text">Shop now</span>
                                            <span className="btn-icon">&rarr;</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3' data-aos="fade-up">
                            <div className='products-imgaesss  position-relative' >
                                <img src={Ninja2} alt='Ninja' />
                                <div className='ninja-text'>
                                    <h5 className='ninja-patches'>Ninja Patches</h5>
                                    <p className='custom-patches'>Shop custom patches with free, quick delivery. Use a Heat Press or iron for application. No art or setup fees.</p>
                                    <div className='ninja-btn'>
                                        <button className="btn btn-primary hover-button button--solid  ninja-shop-btn">
                                            <span className="btn-text">Shop now</span>
                                            <span className="btn-icon">&rarr;</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div >
    )
}

export default Started