import React from "react";
import "./contact.css";
import { Link } from "react-router-dom";

const ContactUs = () => {
   window.scroll(0, 0);
  return (
    <div className="contactus">
      <div className="container">
        <div className="inner-content text-start  pl-5 mb-5 pb-5">
          <h1>Contact Us</h1>
          <p>
            Our transfer specialists and design experts are available to help 7
            days a week and provide three easy ways to get in touch.
          </p>
          <div className="inner-box">
            <h6>Chat</h6>
            <p>Available 24 hours a Day. 7 days a Week. 365 Days a Year.</p>
          </div>
          <div className="inner-box">
            <h6>Phone</h6>
            <Link>
              {" "}
              <p style={{ textDecoration: "underline" }}>1-888-356-3665</p>
            </Link>
            <p>
              Mon-Fri: 8:00am - 9:00pm EST
              <br /> Sat- Sun: 9am - 5pm EST
            </p>
          </div>
          <div className="inner-box">
            <h6>Email</h6>
            <br />
            <Link>
              {" "}
              <p style={{ textDecoration: "underline" }}>
                service@ninjatransfers.com
              </p>
            </Link>
            <br />
          </div>
          <div className="inner-box">
            <p>
              709 N 2nd St <br />
              Suite 400 <br />
              Philadelphia, PA 19123
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
