import React from 'react';

const ProductBox = ({ productData }) => {
    console.log(productData, "Productbox");
    const productStyles = { color: "#03406c" };
    const buttonStyles = { background: "#03406c", color: "#fff", border: "1px solid", borderRadius: "6px", padding: "5px 7px" };

    if (!productData || !productData.variations || productData.variations.length === 0) {
        return null;
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='d-flex gap-2 retail-price'>
                    {productData.variations.map((variation, index) => (
                        <div key={index} className='col-md-6 mt-5 products-color'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='text-start'>
                                    <h5 style={productStyles}><b>{variation.attribute_name}</b></h5>
                                </div>
                                <div className='text-end'>
                                    <button style={buttonStyles}>Save {Math.round((1 - (variation.pricing.sale_price / variation.pricing.regular_price)) * 100)}%</button>
                                </div>
                            </div>
                            <div className='text-start mt-3' style={productStyles}>
                                <p className='p-0 m-0'>Starting at</p>
                                <h3>${variation.pricing.sale_price}</h3>
                                <h6>Retail Price: ${variation.pricing.regular_price}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProductBox;
