import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Spinner } from "react-bootstrap";
import Youtube from "../../assets/images/youtube.png";
import PlaceholderImage from "../../assets/images/Group1000004028.png";

const Videos = () => {
  window.scroll(0, 0);
  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [imageError, setImageError] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { slug } = useParams();
  const [title, setTitle] = useState("");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/all/videos/category-wise/${slug}`
        );
        setTitle(response.data.data.title);
        setVideos(response.data.data.video);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };
    fetchVideoData();
  }, [slug]);

  const getVideoId = (url) => {
    const regex =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regex);
    return match ? match[2] : null;
  };

  const playVideo = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div>
      <Container>
        <Row className="mt-5 mb-5 ">
          <div className=" transfing d-flex transfers mt-5">
            <div className="d-flex">
              <h1 className="transfer-review dtf-text text-start mx-5">
                {title}
              </h1>
              <div className="ml-auto hover-button-read"></div>
            </div>
            <div>
              <span className="line-vertical"></span>
            </div>
            <div>
              <p className="custom-review mx-3">
                Everything DTF You Need to Know and Learn
              </p>
            </div>
          </div>
        </Row>
        <Row >
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            videos.map((video, index) => (
              <div key={video.id} className="col-md-3 mb-3 mt-3 tut-card">
                <div className="tut-img position-relative">
                  {selectedVideo && selectedVideo.media_id === video.media_id ? (
                    <div className="video-player">
                      {video.type === "youtube" ? (
                        <iframe
                          width="100%"
                          loading="lazy"
                          src={`https://www.youtube.com/embed/${getVideoId(
                            video.link
                          )}`}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <iframe loading="lazy" src={`https://player.vimeo.com/video/${video.embeded_link}?h=5a5572cc05&autoplay=1&title=0&byline=0&portrait=0`} width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      )}
                    </div>
                  ) : (
                    <>
                      <img
                        className="inner-img"
                        src={
                          imageError
                            ? PlaceholderImage
                            : video?.media?.link
                              ? `${basePath}${video?.media?.link}`
                              : PlaceholderImage
                        }
                        alt={`video-${index}`}
                        onError={handleImageError}
                        style={{
                          transition: "opacity 0.5s ease-in-out",
                          backgroundColor: "#f2f2f2",
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <div
                        className="youtube-img"
                        onClick={() => playVideo(video)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={Youtube} alt="youtube" />
                      </div>
                    </>
                  )}
                </div>
                <Link
                  className="text-black"
                  to={`/pages/tutorial/${video.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="tut-content">
                    <p className="tut-title mt-3">{video.title}</p>
                    <p
                      className="tut-content mt-3"
                      dangerouslySetInnerHTML={{
                        __html: video.content.substring(0, 100) + "...",
                      }}
                    />
                  </div>
                </Link>
              </div>
            ))
          )}
        </Row>
      </Container>
    </div>
  );
};
export default Videos;
