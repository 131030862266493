import React from 'react'

const DeliveryButton = () => {
    return (
        <div className='Container'>
            <div className='row'>
                <button className='get-delivery-date' >   Get Delivery Date</button>
            </div> </div>
    )
}

export default DeliveryButton