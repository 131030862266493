import React, { useEffect, useState } from "react";
import "./Footer.css";
import Facebook from "../../assets/images/fb1.png";
import LinkedIn from "../../assets/images/linkedin.png";
import Insta from "../../assets/images/instgram.png";
import Twitter from "../../assets/images/twitter.png";
import FooterLogo from "../../assets/images/footer-logo1.png";
import Phone from "../../assets/images/phone.png";
import Email from "../../assets/images/email.png";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dynamic-pages/all`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        if (jsonData.success) {
          setPages(jsonData.data);
        } else {
          throw new Error(jsonData.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    return () => {
      setPages([]);
    };
  }, []);

  return (
    <section className="footer mt-5 ">
      <div className="container-fluid pt-2 ">
        <div className="row mt-2 align-items-center ">
          <div className="col-md-4 text-center text-md-start footer-logo mb-3 ps-5">
            <img src={FooterLogo} alt="Footer-logo" />
          </div>
          <div className="col-md-4  text-center text-md-start">
            <p className="timing close-week">
              Monday-Friday 9:00am-5:00pm - Closed Weekends
            </p>
          </div>
          <div className="col-md-4  text-center text-md-start ">
            <div className="d-flex justify-content-end pe-5 ">
              <p className="mx-3">
                <img src={Facebook} alt="facebbok" />
              </p>
              <p className="mx-2">
                <img src={LinkedIn} alt="Linkedin" />
              </p>
              <p className="mx-2">
                <img src={Twitter} alt="twitter" />
              </p>
              <p className="ms-2">
                <img src={Insta} alt="insta" />
              </p>
            </div>
          </div>
        </div>
        <hr style={{ color: "#E5E5E5" }} />
        <div className="row">
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Support</h4>
              </li>
              <li>
                <Link to="/faq">Faq</Link>
              </li>
              {pages.map(page => (
                <li key={page.slug}>
                  <Link to={`/pages/${page.slug}`}>{page.title}</Link>
                </li>
              ))}
              { /* <li>
                <Link to="/pages/trade-shows">Trade Shows Schedule</Link>
            </li> */}
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Resources</h4>
              </li>
              <li>
                <Link to="">Heat Transfer Size Placement Guide</Link>
              </li>
              <li>
                <Link to="">DTF vs. DTG</Link>
              </li>
              <li>
                <Link to="">DTF vs. Screen Printing</Link>
              </li>
              <li>
                <Link to="">How to Press DTF Transfers</Link>
              </li>
              <li>
                <Link to="">How to Apply UV DTF 3D</Link>
              </li>
              <li>
                <Link to="">PermaStickers™</Link>
              </li>
              <li>
                <Link to="">How Long Do DTF Transfers Last?</Link>
              </li>
              <li>
                <Link to="">What is a DTF Transfer?</Link>
              </li>
              <li>
                <Link to="">How DTF Transfers Are Made</Link>
              </li>
              <li>
                <Link to="">Tools & Tips</Link>
              </li>
              <li>
                <Link to="/pages/blog">View Blog</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Customer Care</h4>
              </li>
              <li>
                <Link to="/register">My Account</Link>
              </li>
              <li>
                <Link to="/pages/anything-transfer-rewards">
                  Anything Transfer Rewards
                </Link>
              </li>
              <li>
                <Link to="/pages/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/pages/content-standards">Content Standards</Link>
              </li>
              <li>
                <Link to="/pages/satisfaction-guarante">
                  Satisfaction Guarantee
                </Link>
              </li>
              <li>
                <Link to="">How They're Made</Link>
              </li>
              <li>
                <Link to="/policies/refund-policy">
                  Refund & Reprint Policy
                </Link>
              </li>
              <li>
                <Link to="">Order Issue Form</Link>
              </li>
              <li>
                <Link to="/policies/terms-of-service">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <h4>Talk to a Anything Transfer</h4>
              <p className="timing ">Chat</p>
              <p className="timing">
                Available 24 hours a Day. <br />7 days a Week. 365 Days
                <br /> a Year.
              </p>
              <p className="timing need-help">Phone</p>
              <p className="timing">
                Mon-Fri: 9am - 9pm EST
                <br /> Sat- Sun: 9am - 5pm EST
              </p>
              <p className="timing">
                <img src={Phone} alt="Phone" />
                <span className="mx-2">(855) 927 - 7273</span>
              </p>
              <p className="timing">
                <img src={Email} alt="Email" />
                <span className="mx-2">care@anythingapparel.com</span>
              </p>
              <p className="timing mb-5 ">
                6833 Amboy Rd, Staten Island, <br />
                NY 10309
              </p>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Our Products</h4>
              </li>
              <li>
                <Link to="">DTF Transfers</Link>
              </li>
              <li>
                <Link to="">DTF Gang Sheet Builder</Link>
              </li>
              <li>
                <Link to="">DTF 3D PermaStickers™</Link>
              </li>
              <li>
                <Link to="">Consumables</Link>
              </li>
              <li>
                <Link to="">DTF Supplies</Link>
              </li>
              <li>
                <Link to="">Free Sample Pack</Link>
              </li>
              <li>
                <Link to="">Custom Transfers</Link>
              </li>
              <li>
                <Link to="">Custom Stickers</Link>
              </li>
              <li>
                <Link to="">Custom Decals</Link>
              </li>
              <li>
                <Link to="">Anything Transfers Merch</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Anything Transfer Blanks</h4>
              </li>
              <li>
                <Link to="">Blank T-Shirts</Link>
              </li>
              <li>
                <Link to="">Blank Short Sleeve</Link>
              </li>
              <li>
                <Link to="">Blank Long Sleeve</Link>
              </li>
              <li>
                <Link to="">Blank Hoodies</Link>
              </li>
              <li>
                <Link to="">Blank Sweatshirts</Link>
              </li>
              <li>
                <Link to="">Blank Crewnecks</Link>
              </li>
              <li>
                <Link to="">Blank Hats</Link>
              </li>
              <li>
                <Link to="">Blank Baseball Caps</Link>
              </li>
              <li>
                <Link to="">View All</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Custom Transfers</h4>
              </li>
              <li>
                <Link to="">Ready-To-Press DTF Transfers</Link>
              </li>
              <li>
                <Link to="">Transfers for T-Shirts</Link>
              </li>
              <li>
                <Link to="">Transfers for Hats</Link>
              </li>
              <li>
                <Link to="">Pricing Questions</Link>
              </li>
              <li>
                <Link to="">Transfers for Aprons</Link>
              </li>
              <li>
                <Link to="">Transfers for Jackets</Link>
              </li>
              <li>
                <Link to="">Transfers for Neck Labels</Link>
              </li>
              <li>
                <Link to="">Transfers for Socks</Link>
              </li>
              <li>
                <Link to="">Transfers for Bags</Link>
              </li>
              <li>
                <Link to="">DTF Transfers</Link>
              </li>
              <li>
                <Link to="">Custom Numbers</Link>
              </li>
              <li>
                <Link to="">View All</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-xs-6 col-12 col-sm-12 text-md-start ">
            <ul>
              <li>
                <h4>Featured Products</h4>
              </li>
              <li>
                <Link to="">Car Decals</Link>
              </li>
              <li>
                <Link to="">Car Window Decals</Link>
              </li>
              <li>
                <Link to="">Football Helmet Decals</Link>
              </li>
              <li>
                <Link to="">Bike Decals</Link>
              </li>
              <li>
                <Link to="">Stickers for Water Bottles</Link>
              </li>
              <li>
                <Link to="">Stickers for Laptops</Link>
              </li>
              <li>
                <Link to="">Stickers for Phone Cases</Link>
              </li>
              <li>
                <Link to="">Stickers for Cups</Link>
              </li>
              <li>
                <Link to="">Custom Stickers</Link>
              </li>
              <li>
                <Link to="">Rhinestone Transfers</Link>
              </li>
              <li>
                <Link to="">Ninja Patches</Link>
              </li>
              <li>
                <Link to="">View All</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 text-center  footer-logo mt-5 timing">
              Privacy Policy IP Property Policy IP Copyright Infringement Form
              CCPA OKEO-TEX Certification
            </div>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-md-6">
            <p className="text-start copyright mb-2 mt-1 timing">
              © Copyright 2024 Anything Apparel | All Rights Reserved
            </p>
          </div>
          <div className="col-md-6">
            <div className="text-end cards-imgaes mb-2 mt-1 timing">
              <div className="  ">
                English
                <span className="  ">(USD $) </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
