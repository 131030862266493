import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Belt = () => (
    <section className="belt">
        <div className="container">
            <div className="row">
                {[...Array(4)].map((_, index) => (
                    <div key={index} className="col-md-3 col-lg-3 col-6 gap-2">
                        <div className="card-belt">
                            <img src="/images/image_2024_04_04_21_12_27_049.svg" alt="" />
                            <div className="card-body text-center">
                                <h5 className="card-title">White Belt</h5>
                                <p>Sign Up</p>
                                <ul>
                                    <li className="tick-icn">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="belt-list">1 Point per dollar spent</div>
                                    </li>
                                    <li className="tick-icn">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="belt-list">Double Points Days</div>
                                    </li>
                                    <li className="tick-icn">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="belt-list">500 Birthday Points</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

export default Belt;
