// import React from 'react';
// import "./Home.css";
// import Banner from './Banner';
// import Review from './Review';
// import Upload from './Upload';
// import Features from './Features';
// import Started from './Started';
// import Categories from './Categories';
// import Faq from './Faq';
// import Blog from './Blog';
// import Tutorial from './Tutorial';
// import Steps from './Steps';

// const Home = () => {
//     return (
//         <div>
//             <Banner />
//             <Review />
//             <Upload />
//             <Features />
//             <Started />
//             <Categories />
//             <Faq />
//             <Steps />
//             <Tutorial />
//             <Blog />
//         </div>
//     )
// }

// export default Home;

import React, { useEffect, useState } from "react";
import "./Home.css";
import Banner from "./Banner";
import Review from "./Review";
import Upload from "./Upload";
import Features from "./Features";
import Started from "./Started";
import Categories from "./Categories";
import Faq from "./Faq";
import Steps from "./Steps";
import Tutorial from "./Tutorial";
import Blog from "./Blog";

const Home = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [videos, setVideos] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/home/data`);
      const data = await response.json();
      setTestimonials(data.data.testimonials);
      setVideos(data.data.videos);
      setFaqs(data.data.faqs);
      setBlogs(data.data.blogs);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Banner />
      <Review testimonials={testimonials} />
      <Upload />
      <Features />
      <Started />
      <Categories />
      <Faq faqs={faqs} />
      <Steps />
      <Tutorial videos={videos} />
      <Blog blogs={blogs} />
    </div>
  );
};

export default Home;
