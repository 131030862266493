import React from 'react';
import { Link, useLocation } from 'react-router-dom';


function NotFound() {
     window.scroll(0, 0);
    const location = useLocation();
    const currentUrl = location.pathname + location.search;
    return (
        <section className="mt-5">

            <div className="w-100 d-flex justify-content-center align-items-center mt-5">
                <div className="col-sm-10 text-center">
                    <h1 className="error-page mt-4" >
                        404
                    </h1>
                    <div className="four_zero_four_bg mt-5"></div>
                    <div>
                        <h3 className="h2 mt-2">Looks like you're lost</h3>
                        <p>the page you're looking for is not found!</p>
                        <Link
                            to="/"
                            className="btn rounded-pill mb-4 error-homepage"

                        >
                            Go to the homepage
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NotFound;
