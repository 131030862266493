import React, { useState, useEffect } from "react";
import Slidere1 from "../../assets/images/Slider1.png";
import Slidere2 from "../../assets/images/Slider2.png";
import Slidere3 from "../../assets/images/Sider3.png";
import Feature from "../../assets/images/Feature1.png";
import Feature1 from "../../assets/images/feature.png";
import Feature2 from "../../assets/images/feature3.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Features = () => {
  AOS.init();
  const [openIndex, setOpenIndex] = useState(0);

  const questions = [
    {
      question: "Crisp Bright Whites",
      answer: "Answer to the Crisp Bright Whites question.",
    },
    {
      question: "Crisp Bright Whites1",
      answer: "Answer to the Crisp Bright Whites1 question.",
    },
    {
      question: "Crisp Bright Whites2",
      answer: "Answer to the Crisp Bright Whites2 question.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (openIndex + 1) % questions.length;
      setOpenIndex(nextIndex);
    }, 10000);

    return () => clearTimeout(timer);
  }, [openIndex, questions.length]);

  useEffect(() => {
    const switchImage = () => {
      const imgs = document.querySelectorAll('.quality-img img');
      imgs.forEach(img => img.classList.remove('active'));
      if (openIndex !== null) {
        imgs[openIndex].classList.add('active');
      }
    };

    switchImage();
  }, [openIndex]);


  const toggleAnswer = (index) => {
    setOpenIndex(prevIndex => prevIndex === index ? null : index);
  };

  return (
    <div className="features mt-5">
      <section>
        <div className="container">
          <div className="row  "   >
            <div className="col-md-12">
              <div className="text-start">
                <h3 className="features">Features of our DTF Transfers</h3>
                <p className="network">
                  No more second guessing your artwork, product style, or
                  product color combination. Our high quality DTF transfers are
                  brighter, more durable, stretchier, and higher detail than any
                  other transfer company.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-3  dtf-tranfer-slider">
            <div className="col-md-5" >
              <div className="quality-img position-relative">
                <img
                  className="active"
                  src={Slidere1}
                  alt=""
                />
                <img
                  src={Slidere2}
                  alt=""
                />
                <img
                  src={Slidere3}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7 quality-img-card">
              <div className="fine-detail card">
                <div className="gamut">
                  <h5 className="incredible">
                    Incredible Image Quality and Fine Details
                  </h5>
                  <p className="formulated mb-3">
                    Our specially formulated DTF inks enable us to print deeper
                    colors and a wider color gamut on our custom DTF transfers.
                    Our color registration is performed to G7 Color Standards
                    certified on a weekly basis for near-perfect machine
                    linearization.
                  </p>

                  {questions.map((item, index) => (
                    <div key={index} className="question-wrapper">
                      <p
                        className={`bright border-top mb-3 ${openIndex === index ? "active" : ""
                          }`}
                        onClick={() => toggleAnswer(index)}
                      >
                        {item.question}
                      </p>
                      {openIndex === index && (
                        <p className="text-start mb-3 mx-3 px-5 specially">
                          {item.answer}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 beautiful-color" >
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3" data-aos="fade-up" >
              <div className="feature" >
                <div className="feature-img">
                  <img src={Feature} alt="feature1" className="inner-img1" />
                </div>
                <div>
                  <h5 className="beautiful text-start px-3 pt-3">
                    Beautiful Color Contrast
                  </h5>
                  <p className="text-start pt-3 mx-3 specially">
                    Our specially formulated DTF inks enable us to print deeper
                    colors and a wider color gamut on our custom DTF transfers.
                    Our color registration is performed to G7 Color Standards
                    certified on a weekly basis for near-perfect machine
                    linearization.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3" data-aos="fade-up">
              <div className="feature">
                <div className="feature-img">
                  <img src={Feature1} alt="feature2" className="inner-img1" />
                </div>
                <div>
                  <h5 className="beautiful text-start px-3 pt-3">
                    Beautiful Color Contrast
                  </h5>
                  <p className="text-start mx-3 pt-3 specially">
                    Our specially formulated DTF inks enable us to print deeper
                    colors and a wider color gamut on our custom DTF transfers.
                    Our color registration is performed to G7 Color Standards
                    certified on a weekly basis for near-perfect machine
                    linearization.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3" data-aos="fade-up">
              <div className="feature">
                <div className="feature-img">
                  <img src={Feature2} alt="feature3" className="inner-img1" />
                </div>
                <div>
                  <h5 className="beautiful text-start px-3 pt-3">
                    Beautiful Color Contrast
                  </h5>
                  <p className="text-start mx-3 pt-3 specially">
                    Our specially formulated DTF inks enable us to print deeper
                    colors and a wider color gamut on our custom DTF transfers.
                    Our color registration is performed to G7 Color Standards
                    certified on a weekly basis for near-perfect machine
                    linearization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
