import React, { useEffect, useState } from "react";
import "./Header.css"
import { Link } from "react-router-dom";
import ListItem1 from "./Listitem";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/images/loggo.png";

import DTF1 from "../../assets/images/dtf1.png"
import DTF2 from "../../assets/images/dtf2.jpg"
import DTF3 from "../../assets/images/dtf3.jpg"
import UTF1 from "../../assets/images/utf1.jpg"
import UTF2 from "../../assets/images/utf2.jpg"
import UTF3 from "../../assets/images/utf3.jpg"
import Patch1 from "../../assets/images/custompatch1.jpg"
import Patch2 from "../../assets/images/custompatch2.jpg"
import Patch3 from "../../assets/images/custompatch3.jpg"
import Patch4 from "../../assets/images/custompatch4.jpg"
import Patch5 from "../../assets/images/custompatch5.png"
import Patch6 from "../../assets/images/custompatch6.jpg"
import Patch7 from "../../assets/images/custompatch7.jpg"
import Patch8 from "../../assets/images/custompatch8.jpg"
import Patch9 from "../../assets/images/custompatch9.png"
import Supply1 from "../../assets/images/supply1.jpg"
import Supply2 from "../../assets/images/supply2.jpg"
import Supply3 from "../../assets/images/supply3.png"
import Supply4 from "../../assets/images/supply4.jpg"
import Supply5 from "../../assets/images/supply25.jpg"
import Supply6 from "../../assets/images/supply6.jpg"
import Supply7 from "../../assets/images/supply7.jpg"
import Supply8 from "../../assets/images/supply8.png"
import Supply9 from "../../assets/images/supply9.jpg"
import Supply10 from "../../assets/images/supply10.jpg"
import Supply11 from "../../assets/images/supply11.jpg"
import Supply12 from "../../assets/images/supply12.jpg"
import Supply13 from "../../assets/images/supply13.jpg"
import Supply14 from "../../assets/images/supply14.jpg"
import Supply15 from "../../assets/images/supply15.jpg"
import Cart from "../../assets/images/cart.png"
import { colors } from "@material-ui/core";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";



function Header() {

  const [categories, setCategories] = useState([]);

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const [showTemplateMenu, setShowTemplateMenu] = useState(false);
  const [showDtfMenu, setShowDtfMenu] = useState(false);
  const [showUvMenu, setShowUvMenu] = useState(false);
  const [showPatchesMenu, setShowPatchesMenu] = useState(false);
  const [showSupportMenu, setShowSupportMenu] = useState(false);
  const [showOpenMenu, setShowOpenMenu] = useState(false);
  const [showCloseMenu, setShowCloseMenu] = useState(false);

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const handleSubMenuHover = () => {
    setShowSubMenu(true);
  };

  const handleSubMenuLeave = () => {
    setShowSubMenu(false);
  };
  const handleSubMenuClick = () => {
    if (!showSubMenu) {
      setShowSubMenu(true);
    }
  };
  // help menu
  const toggleHelpMenu = () => {
    setShowHelpMenu(!showHelpMenu);
  };
  const handleHelpMenuHover = () => {
    setShowHelpMenu(true);
  };

  const handleHelpMenuLeave = () => {
    setShowHelpMenu(false);
  };
  const handleMenuClick = () => {
    if (!showHelpMenu) {
      setShowHelpMenu(true);
    }
  };
  // Template menu
  const toggleTemplateMenu = () => {
    setShowTemplateMenu(!showTemplateMenu);
  };
  const handleTemplateMenuHover = () => {
    setShowTemplateMenu(true);
  };

  const handleTemplateMenuLeave = () => {
    setShowTemplateMenu(false);
  };
  const handleTemplateMenuClick = () => {
    if (!showTemplateMenu) {
      setShowTemplateMenu(true);
    }
  };
  // Dtf
  const toggleDtfMenu = () => {
    setShowDtfMenu(!showDtfMenu);
  };
  const handleDtfMenuHover = () => {
    setShowDtfMenu(true);
  };

  const handleDtfMenuLeave = () => {
    setShowDtfMenu(false);
  };
  const handleDtfMenuClick = () => {
    if (!showDtfMenu) {
      setShowDtfMenu(true);
    }
  };
  // Uv
  const toggleUvMenu = () => {
    setShowUvMenu(!showUvMenu);
  };
  const handleUvMenuHover = () => {
    setShowUvMenu(true);
  };

  const handleUvMenuLeave = () => {
    setShowUvMenu(false);
  };
  const handleUvMenuClick = () => {
    if (!showUvMenu) {
      setShowUvMenu(true);
    }
  };
  // Patches
  const togglePatchesMenu = () => {
    setShowPatchesMenu(!showPatchesMenu);
  };
  const handlePatchesMenuHover = () => {
    setShowPatchesMenu(true);
  };

  const handlePatchesMenuLeave = () => {
    setShowPatchesMenu(false);
  };
  const handlePatchesMenuClick = () => {
    if (!showPatchesMenu) {
      setShowPatchesMenu(true);
    }
  };
  // Support
  const toggleSupportMenu = () => {
    setShowSupportMenu(!showSupportMenu);
  };
  const handleSupportMenuHover = () => {
    setShowSupportMenu(true);
  };

  const handleSupportMenuLeave = () => {
    setShowSupportMenu(false);
  };
  const handleSupportMenuClick = () => {
    if (!showSupportMenu) {
      setShowSupportMenu(true);
    }
  };

  const apparelItems = [
    { label: "kids", link: "#!" },
    { label: "Men's", link: "#!" },
    { label: "Women's", link: "#!" },
    { label: "Unisex/Non-binary", link: "#!" },
    { label: "hats & beanies", link: "#!" },
    { label: "hoodies & sweats", link: "#!" },
    { label: "polo shirts & fleece", link: "#!" },
    { label: "jackets & vests", link: "#!" },
    { label: "Crew / Team Jerseys", link: "#!" },
    { label: "Pants, Shorts & Leggings", link: "#!" },
    { label: "Uniforms & Workgear", link: "#!" },
  ];
  const Support = [
    {
      title: " Support",
      items: [
        "Contact Us",
        "Order Issue Submission",
        "Blank Apparel Returns",
        "Submit Tax Exempt Forms",
      ],
    },

    {
      title: "Explore",
      items: [
        "Affiliate Program - Join Today",
        "Reviews",
        "Blog",
        "Anything Transfers Merch",
        "Trade Shows Schedule",
        "hoodies & sweats",
        "polo shirts & fleece",
        "jackets & vests",
        "Crew / Team Jerseys",
        "Pants, Shorts & Leggings",
      ],
    },
    {
      title: "Help Center",
      items: [
        "Ordering Questions",
        "Artwork & Sizing Questions",
        "Shipping Questions",
        "Tutorial Videos",
        "Tools & Tips",
        "Pressing Instructions",
        "View All",
      ],
    },
  ];
  const templates = [
    { imageUrl: DTF1, label: "DTF Transfers By Size" },
    { imageUrl: DTF2, label: "Ninja Gang Sheet Builder" },
    { imageUrl: DTF3, label: "DTF Gang Sheets" },

  ];
  const customPatches = [
    { imageUrl: Patch1, label: "EMBROIDERED PATCHES" },
    { imageUrl: Patch2, label: "FULL COLOR PRINTED PATCHES" },
    { imageUrl: Patch3, label: "PRINT + EMBROIDERY PATCHES" },
    { imageUrl: Patch4, label: "SILICONE PATCHES" },
    { imageUrl: Patch5, label: "LEATHER PATCHES " },
    { imageUrl: Patch6, label: "FAUX LEATHER PATCHES " },
    { imageUrl: Patch7, label: "WOVEN PATCHES " },
    { imageUrl: Patch8, label: "3D EMBROIDERY PATCHES" },
    { imageUrl: Patch9, label: "RHINESTONE TRANSFERS" },

  ];
  const templates12 = [
    { imageUrl: "/images/dtf-pressing.webp", label: "ALIGNMENT RULER SET" },
    { imageUrl: "/images/dtf-pressing.webp", label: "ALIGNMENT RULER SET" },
    { imageUrl: "/images/dtf-pressing.webp", label: "ALIGNMENT RULER SET" },
    { imageUrl: "/images/dtf-pressing.webp", label: "ALIGNMENT RULER SET" },
  ];

  const supplies = [
    { imageUrl: Supply1, label: "DTF Alignment Ruler Set " },
    { imageUrl: Supply2, label: "Protective Guard Sheet for Heat Press" },
    { imageUrl: Supply3, label: "Pillow Set" },
    { imageUrl: Supply4, label: "Ninja Cool Peel Air Blower" },
    { imageUrl: Supply5, label: "Thermal Heat Tape" },
    { imageUrl: Supply6, label: "NINJA PREMIUM DTF INKS" },
    { imageUrl: Supply7, label: "NINJA PREMIUM DTF ROLLS" },
    { imageUrl: Supply8, label: "NINJA DTF TPU POWDER" },
    { imageUrl: Supply9, label: "PROTECTIVE GUARD MAGNET SET" },
    { imageUrl: Supply10, label: "EASY GLIDE SCISSORS" },
    { imageUrl: Supply11, label: "PRECISION KNIFE" },
    { imageUrl: Supply12, label: "SELF HEALING CUTTING MAT SET" },
    { imageUrl: Supply13, label: "NINJA TRANSFERS SHIRT FOLDING BOARD" },
    { imageUrl: Supply14, label: "UV APPLICATION SQUEEGEE" },
    { imageUrl: Supply15, label: "UV APPLICATION SQUEEGEE" },
  ];

  const uvsubmenu = [
    { imageUrl: UTF1, label: "UV DTF 3D PermaStickers™ by Size" },
    { imageUrl: UTF2, label: "UV DTF 3D PermaStickers™ - Gang Sheet Builder" },
    { imageUrl: UTF3, label: "UV DTF 3D PermaStickers™ Gang Sheets" },

  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/blank-apparel`);
        const categoriesData = response.data.data;
        if (Array.isArray(categoriesData)) {
          setCategories(categoriesData);
        } else {
          console.error('Expected an array but got:', categoriesData);
        }
      } catch (error) {
        console.error('Error fetching the data:', error);
      }
    };

    fetchData();
  }, []);

  const blankApparelCategory = categories.find(category => category.title === "Blank Apparel");

  const categories1 = [
    {
      title: "Blank T-Shirts",
      items: [
        "kids",
        "Men's",
        "Women's",
        "Unisex/Non-binary",
        "hats & beanies",
        "hoodies & sweats",
        "polo shirts & fleece",
        "jackets & vests",
        "Crew / Team Jerseys",
        "Pants, Shorts & Leggings",
      ],
    },
  ];

  const toggleOpenMenu = () => {
    if (!showOpenMenu) {
      document.getElementById("mySidenav").style.width = "100%";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
    setShowOpenMenu(!showOpenMenu);
  };
  const toggleCloseMenu = () => {
    if (!showCloseMenu) {
      document.getElementById("mySidenav").style.width = "0";
    } else {
      document.getElementById("mySidenav").style.width = "100% ";
    }
    setShowCloseMenu(!showCloseMenu);
  };

  return (
    <>
      <div id="mySidenav" className={`sidenav ${showOpenMenu ? "open" : ""}`}>
        <div className="nav-header text-start">
          <img src={Logo} className="img w-50 " alt="Logo" />
          <a href="#!" className="closebtn" onClick={toggleCloseMenu}>
            ×
          </a>{" "}
        </div>
        <ul className=" navbar-nav1 me-auto mb-2 mb-lg-0 ">
          <li
            className="nav-item1"
            onMouseEnter={handleDtfMenuHover}
            onMouseLeave={handleDtfMenuLeave}
            onClick={handleDtfMenuClick}
          >
            <Link
              className="nav-link1"
              aria-current="page"
              onClick={toggleDtfMenu}
            >
              DTF Transfers
              <FontAwesomeIcon icon={faArrowRight} className="px-3" />
            </Link>
            {showDtfMenu && (
              <div className="productlist-dtf">
                <div className="row   pt-3 pb-3 text-align">
                  {templates.map((template, index) => (
                    <div key={index} className="col-6 mx-auto mb-md-0 mb-4">
                      <div className="template-img-dtf-supplies">
                        <img src={template.imageUrl} alt="" />
                        <div className="tempcontent text-center">{template.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item1"
            onMouseEnter={handleUvMenuHover}
            onMouseLeave={handleUvMenuLeave}
            onClick={handleUvMenuClick}
          >
            <Link
              className="nav-link1"
              aria-current="page"
              onClick={toggleUvMenu}
            >
              UV DTF 3D PermaStickers™
              <FontAwesomeIcon icon={faArrowRight} className="px-3" />

            </Link>
            {showUvMenu && (
              <div className="productlist-dtf">
                <div className="row   pt-3 pb-3 text-align">
                  {uvsubmenu.map((template, index) => (
                    <div key={index} className="col-6 mx-auto mb-md-0 mb-4">
                      <div className="template-img-dtf-supplies">
                        <img src={template.imageUrl} alt="" />
                        <div className="tempcontent text-center">{template.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>


          {blankApparelCategory && (
            <li
              className="nav-item1"
              onMouseEnter={handleSubMenuHover}
              onMouseLeave={handleSubMenuLeave}
              onClick={handleSubMenuClick}
            >
              <Link
                className="nav-link1"
                aria-current="page"
                onClick={toggleSubMenu}
              >
                {blankApparelCategory.title} {" "}
                <FontAwesomeIcon icon={faArrowRight} className="px-3" />

              </Link>
              {showSubMenu && (
                <div className="productlist67">
                  <div className="pt-3 pb-3">


                    {blankApparelCategory.under_child.map((category, index) => (
                      <ListItem1
                        key={index}
                        title={category.title}
                        items={category.child.map(child => child.title)}
                      />
                    ))}


                  </div>
                </div>
              )}
            </li>
          )}




          <li
            className="nav-item1"
            onMouseEnter={handlePatchesMenuHover}
            onMouseLeave={handlePatchesMenuLeave}
            onClick={handlePatchesMenuClick}
          >
            <Link className="nav-link1" onClick={togglePatchesMenu}>
              Custom Patches
              <FontAwesomeIcon icon={faArrowRight} className="px-3" />

            </Link>
            {showPatchesMenu && (
              <div className="productlist-dtf">
                <div className="row   pt-3 pb-3 text-align">
                  {customPatches.map((template, index) => (
                    <div key={index} className="col-6  mx-auto mb-md-0 mb-4">
                      <div className="template-img-dtf-supplies">
                        <img src={template.imageUrl} alt="" />
                        <div className="tempcontent text-center">{template.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item1"
            onMouseEnter={handleTemplateMenuHover}
            onMouseLeave={handleTemplateMenuLeave}
            onClick={handleTemplateMenuClick}
          >
            <Link className="nav-link1" onClick={toggleTemplateMenu}>
              DTF Supplies{" "}
              <FontAwesomeIcon icon={faArrowRight} className="px-3" />

            </Link>
            {showTemplateMenu && (
              <div className="productlist-dtf ">
                <div className="row   pt-3 pb-3 text-align">
                  {supplies.map((template, index) => (
                    <div key={index} className="col-6 mx-auto mb-md-0 mb-4">
                      <div className="template-img-dtf-supplies">
                        <img src={template.imageUrl} alt="" />
                        <div className="tempcontent text-center">{template.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item1"
            onMouseEnter={handleHelpMenuHover}
            onMouseLeave={handleHelpMenuLeave}
            onClick={handleMenuClick}
          >
            <Link className="nav-link1" onClick={toggleHelpMenu}>
              Design Library{" "}
              <FontAwesomeIcon icon={faArrowRight} className="px-3" />
            </Link>
            {showHelpMenu && (
              <div
                className="productlist67"
              >
                {categories1.map((category, index) => (
                  <ListItem1
                    key={index}
                    title={category.title}
                    items={category.items}
                  />
                ))}
              </div>
            )}
          </li>
          <li
            className="nav-item1"
            onMouseEnter={handleSupportMenuHover}
            onMouseLeave={handleSupportMenuLeave}
            onClick={handleSupportMenuClick}
          >
            <Link className="nav-link1" onClick={toggleSupportMenu}>
              Support
              <FontAwesomeIcon icon={faArrowRight} className="px-3" />
            </Link>
            {showSupportMenu && (
              <div className="productlist67 ">
                {Support.map((category, index) => (
                  <ListItem1
                    key={index}
                    title={category.title}
                    items={category.items}
                  />
                ))}
              </div>
            )}
          </li>
        </ul>
      </div>

      <span
        style={{ fontSize: "30px", cursor: "pointer", visibility: "hidden" }}
        onClick={toggleOpenMenu}
        className="opennav"
      >
        ☰
      </span>
    </>
  );
}

export default Header;

