import React, { useState } from "react";
import { Link } from "react-router-dom";

const ListItem1 = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="text-start">
            <h6 className="side-heading1 px-3">
                {title}{" "}
                <button className="filter-btn" onClick={handleToggle}>
                    {isOpen ? "-" : "+"}
                </button>
            </h6>
            <div
                className={`list-items ${isOpen && items.length > 0 ? "scrollable" : ""
                    }`}
            >
                {items.map((item, index) => (
                    <div key={index}>
                        <Link className="text-reset px-3">
                            {item}
                        </Link>
                    </div>
                ))}
            </div>
            {title !== "Fit1" && <hr />}
        </div>
    );
};

export default ListItem1;
