import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BulkDiscount from './BulkDiscount';
import ProductTitle from './ProductTitle';
import ProductBox from './ProductBox';
import SelectedColor from './SelectedColor';
import ChooseSize from './ChooseSize';
import Shipping from './Shipping';
import OrderInput from './OrderInput';

const ProductDetails = ({ productData }) => {
    console.log(productData, "Detail shortDescription accordions");

    return (
        <div>
            <Row>
                <div className='product-data' style={{ maxHeight: '900px', overflowY: 'auto' }}>
                    <ProductTitle productData={productData} />
                    <ProductBox productData={productData} />
                    <SelectedColor variations={productData.variations} />
                    <ChooseSize productData={productData} />
                    <Shipping productData={productData} />
                    <OrderInput productData={productData} />
                    <div className='col-md-12 mt-5'>
                        <BulkDiscount productData={productData} />
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default ProductDetails;
