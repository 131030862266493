import React, { useEffect, useState } from 'react';
import "./Login.css";
import { Container, Row, Col } from 'react-bootstrap';
import { isEmpty, isUndefined } from "lodash";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash, FaFacebookF, FaGoogle, FaLinkedinIn } from 'react-icons/fa';


const Login = () => {
    window.scroll(0, 0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();



    const validateLogin = () => {
        if (submitted) {
            if (!isUndefined(email)) {
                if (isEmpty(email)) {
                    setEmailError("Email is a required field");
                } else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                    setEmailError("Please enter a valid email");
                } else {
                    setEmailError("");
                }
            }

            if (!isUndefined(password)) {
                if (isEmpty(password)) {
                    setPasswordError("Password is a required field");
                } else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(password))) {
                    setPasswordError("The password field must be at least 8 characters and contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.");
                } else {
                    setPasswordError("");
                }
            }
        }
    };

    useEffect(() => {
        validateLogin();
    }, [email, password, submitted]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        validateLogin();

        if (!emailError && !passwordError) {
            try {
                const response = await axios.post(
                    "https://buit.in/anything-dashbaord//api/v1/login",
                    {
                        email,
                        password,
                    }
                );
                const { token, user } = response.data.data;
                localStorage.setItem('token', token.token);
                localStorage.setItem('user', JSON.stringify(user));
                toast.success(response.data.message);
                navigate('/');
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    const { email: emailErrors, password: passwordErrors } = error.response.data.error;
                    setEmailError(emailErrors ? emailErrors[0] : "");
                    setPasswordError(passwordErrors ? passwordErrors[0] : "");
                } else {
                    toast.error("Failed to authenticate.");
                }
            }
        }
    };


    const googleLogin = (e) => {
        e.preventDefault();
        setSubmitted(true);
        const popup = window.open(
            `${process.env.REACT_APP_API_URL}/social-login/google`,
            "Google Login",
            "width=600,height=400"
        );
        const closePopupListener = () => {
            window.removeEventListener('unload', closePopupListener);
            if (!popup.closed) {
                popup.close();
            }
        };
        window.addEventListener('unload', closePopupListener);
    };
    const handleSuccessfulLogin = () => {
        window.close();
        navigate('/');
    };

    window.addEventListener('message', (event) => {
        if (event.origin === 'https://buit.in/anything-dashbaord') {
            const { token, user } = event.data;
            if (token && user) {
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                toast.success("Successfully logged in with Google.");
                handleSuccessfulLogin();
            } else {
                toast.error("Failed to log in with Google.");
            }
        }
    });




    const facebookLogin = (e) => {
        e.preventDefault();
        setSubmitted(true);
        const popup = window.open(
            "https://buit.in/anything-dashbaord//api/v1/social-login/facebook",
            "Facebook Login",
            "width=600,height=400"
        );

        const popupTimer = setInterval(() => {
            if (popup.closed) {
                clearInterval(popupTimer);
                handlePopupClosed();
            }
        }, 1000);
    };

    const handlePopupClosed = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const user = urlParams.get('user');

        if (token && user) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            toast.success("Successfully logged in with Facebook.");
            navigate('/');
        } else {
            toast.error("Failed to log in with Facebook.");
        }
    };


    const linkedinLogin = (e) => {
        e.preventDefault();
        setSubmitted(true);

        const popup = window.open(
            "https://buit.in/anything-dashbaord//api/v1/social-login/linkedin",
            "LinkedIn Login",
            "width=600,height=400"
        );


        const popupTimer = setInterval(() => {
            if (popup.closed) {
                clearInterval(popupTimer);
                handlePopupClosed1();
            }
        }, 1000);
    };


    const handlePopupClosed1 = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const user = urlParams.get('user');

        if (token && user) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            toast.success("Successfully logged in with LinkedIn.");
            navigate('/');
        } else {
            toast.error("Failed to log in with LinkedIn.");
        }
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <section className='login-img'>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col md={6} lg={5}>
                            <form className='form' onSubmit={handleFormSubmit}>
                                <ToastContainer pauseOnHover />
                                <h2 className=' mt-5 customer'>Customer Login</h2>
                                <hr />
                                <div className="h_buttons">
                                    <div className="h_google_button" data="google" onClick={googleLogin}>
                                        <FaGoogle className="h_google_icon" /> &nbsp;
                                        <span className="h_google_text h_google_full" >Google</span>
                                    </div>
                                    <div className="h_facebook_button" data="facebook" onClick={facebookLogin}>
                                        <FaFacebookF />  &nbsp;
                                        <span className="h_facebook_text h_facebook_full">Facebook</span>
                                    </div>
                                    <div className="h_linkedin_button" data="linkedin" onClick={linkedinLogin}>
                                        <FaLinkedinIn />  &nbsp;
                                        <span className="h_linkedin_text h_linkedin_full">LinkedIn</span>
                                    </div>
                                </div>

                                <div class="h_divider">OR</div>

                                <div className='form-group'>
                                    <div className='email-label'>
                                        <label htmlFor="email">Email Name</label>
                                    </div>
                                    <input
                                        className='form-control px-3'
                                        type="text"
                                        id="email"
                                        placeholder='Email'
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                    <span className="text-danger">{!isUndefined(email) && submitted && emailError}</span>
                                </div>
                                <div className='form-group'>
                                    <div className='email-label'>
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    <div className='password-input'>
                                        <input
                                            className='form-control px-3'
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            placeholder='Password'
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                        />
                                        <span className="password-toggle"
                                            onClick={togglePasswordVisibility}>
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    <span className="text-danger">{!isUndefined(password) && submitted && passwordError}</span>
                                </div>
                                <div className='check-box form-group d-flex justify-content-end mb-2 mt-3'>

                                    <p>
                                        <Link to="/forget-password" className='forgetpwd'>Forgot Password?</Link>
                                    </p>
                                </div>
                                <button
                                    className='submit-login-btn rounded-0 text-capitalize'
                                    type="submit"
                                >
                                    Login
                                </button>
                                <div className='sing-up mt-3 justify-content-between'>
                                    <p>Don’t Have an account?</p>
                                    <h6>
                                        <Link to="/register" className='forgetpwd'>New Customer? Sign Up!</Link>
                                    </h6>
                                </div>
                            </form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Login;
