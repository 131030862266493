import React, { useEffect, useState } from 'react';
import "./Blank.css";
import Tshirts from "../../assets/images/blanimg-png.png";
import SideMenu from './SideMenu';
import MainData from './MainData';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { BsFillFilterSquareFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';

const Blank = () => {
    const [zipCode, setZipCode] = useState('');
    const [displayMode, setDisplayMode] = useState('single');
    const [products, setProducts] = useState([]);
    const location = useLocation();
    const { category } = location.state || {};

    // console.log(category, "category")
    const fetchProducts = () => {
        fetch(`${process.env.REACT_APP_API_URL}/product/category-wise/${category}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setProducts(data.data);
                }
            })
            .catch(error => console.error('Error fetching products:', error));
    };

    useEffect(() => {
        window.scroll(0, 0);
        fetchProducts();
    }, []);

    const handleZipCodeChange = (event) => {
        setZipCode(event.target.value);
    };

    const handleGetDeliveryDates = () => {
        console.log('Zip Code:', zipCode);
    };

    const handleDisplayModeChange = (mode) => {
        setDisplayMode(mode);
    };

    const items = [
        {
            text: "T-Shirts",
            image: Tshirts
        },
        {
            text: "Pants",
            image: Tshirts
        },
        {
            text: "Jackets",
            image: Tshirts
        },
        {
            text: "Hats & Beanies",
            image: Tshirts
        },
        {
            text: "Socks",
            image: Tshirts
        },
        {
            text: "Shoes",
            image: Tshirts
        }
    ];

    return (
        <div className='blank' style={{ background: "#f8f9fa" }}>
            <section className='blankk'>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-md-12'>
                            <h1 className='text-start blank-shirts'>Blank T-Shirts</h1>
                            <p className='bluk-shirts'>Shop in bulk with our premium wholesale blank t-shirts. We offer a wide selection of blank tees at unbeatable wholesale prices. Stock up now and enjoy bulk discounts!</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className='d-flex flex-wrap'>
                            {items.map((item, index) => (
                                <div className='col-md-2 col-lg-2 mb-4 col' key={index}>
                                    <div className='mx-2'>
                                        <div className='blank-data'>
                                            <img src={item.image} alt='design' className="clip clip--circle1" />
                                        </div>
                                        <div className='desgin-text1 text-center mt-2 me-5'>
                                            {item.text}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='zip-code'>
                            <h6 className='text-start mt-5 order-arrive'>When will my order arrive?</h6>
                        </div>
                        <div className='col-md-6'>
                            <div className='text-start mt-3 zip-input d-flex'>
                                <input className='enter-zip-code' type="text" placeholder="Enter Zip Code" value={zipCode} onChange={handleZipCodeChange} />
                                <button onClick={handleGetDeliveryDates} className='mx-3 delivery-dates'>
                                    <span className="button-text">Get Delivery Dates</span>
                                </button>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='text-end mt-3'>
                                <div className='d-flex align-items-center justify-content-end grid-buttton'>
                                    <div className={`grid-icons ${displayMode === 'single' ? 'active' : ''}`}>
                                        <BsFillGrid3X3GapFill
                                            onClick={() => handleDisplayModeChange('single')}
                                            className={`mx-2 delivery-dates-icon ${displayMode === 'single' ? 'active' : ''}`}
                                        />
                                    </div>
                                    <div className={`grid-icons ${displayMode === 'all' ? 'active' : ''} mx-2`}>
                                        <BsFillFilterSquareFill
                                            onClick={() => handleDisplayModeChange('all')}
                                            className={`mx-2 delivery-dates-icon ${displayMode === 'all' ? 'active' : ''}`}
                                        />
                                    </div>
                                    <Dropdown className='mx-3'>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Featured
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Featured</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">Title: A-Z</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Title: Z-A</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Date: New-Old</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Date: Old-New</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">Price: Low to High</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">Price: Hight to Low</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Discount :Hight to Low</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Best Selling</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-3 mt-5'>
                            <SideMenu />
                        </div>
                        <div className='col-md-9 mt-5 mb-5'>
                            <MainData products={products} displayMode={displayMode} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Blank;
