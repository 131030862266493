
import React, { useState } from 'react';
import PlaceholderImage from '../../assets/images/blog1.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Blog = ({ blogs }) => {
    AOS.init();

    // console.log(blogs, "blogs")
    const basePath = process.env.REACT_APP_IMAGE_URL;
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <div className="blog">
            <section className="blogs mt-2">
                <div className="container">
                    <div className="row">
                        <h5 className="blog-head text-start">Blog</h5>
                        {blogs.map((blog, index) => (

                            <div className="col-md-4 mt-5 " key={index} data-aos="fade-up" data-aos-anchor-placement="left-right">
                                <div className="blog-content" >
                                    <div className="blog-img zoom-out-on-hover">


                                        <img
                                            src={imageError ? PlaceholderImage : blog.media.link ? `${basePath}${blog.media.link}` : PlaceholderImage}
                                            alt={`blog-${index}`}
                                            onError={handleImageError}
                                            style={{
                                                transition: 'opacity 0.5s ease-in-out',
                                                backgroundColor: '#f2f2f2',
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                    <div className="blog-text">
                                        <p className="date text-start pt-3">{blog.created_at}</p>
                                        <h5 className="blog-title text-start underline-on-hover pt-3">{blog.title}</h5>
                                        <p
                                            className="blog-desc text-start mt-3"
                                            dangerouslySetInnerHTML={{
                                                __html: blog.content.substring(0, 100) + '...',
                                            }}
                                        />

                                        <div className="blog-readmore-button text-start mx-3 mb-5">
                                            <div className="review-more-btn mt-4">

                                                <Link to={`/pages/blog-detail/${blog.slug}`}>
                                                    <button className="btn btn-primary hover-button button--outline2 mt-2">
                                                        <span className="btn-text">Read More</span>
                                                        <span className="btn-icon">&rarr;</span>
                                                    </button>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}

                        <div className="row mt-2">
                            <Link to="/pages/blog">
                                <button className="btn btn-primary hover-button visi-blog-btn ">
                                    <span className="btn-text">Visit the Blog</span>
                                    <span className="btn-icon ">&rarr;</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Blog;
