import React from 'react';
import Star from "../../assets/images/star.png"

const Banner = () => {
    return (
        <div className='banner'>
            <section>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-6 order-md-2'>
                            <div className='baner-section-img'>

                            </div>
                        </div>
                        <div className='col-md-6 order-md-1 '>
                            <div className='text-start'>
                                <p className='stay text-start mt-5 '>Stay <span>and</span> Design </p>
                                <p className='custom text-start  p-0'>Custom-Made to create <br />
                                    Something fast and easy</p>

                                <div className="pt-4 banner-btn">
                                    <button className="btn btn-primary hover-button button--solid  ">
                                        <span className="btn-text">Order Transfers by Size</span>
                                        <span className="btn-icon">&rarr;</span>
                                    </button>
                                    <button className="btn btn-primary hover-button button--outline mt-3 ">

                                        <span className="btn-text">Order Transfers by Size</span>
                                        <span className="btn-icon">&rarr;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Banner;
