import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import "./Productdetail.css";
import ProductImage from "../../assets/images/product11.png";
import ProducImage2 from "../../assets/images/product6.png";
import ProductSlider from "./ProductSlider";

const Productdetail = () => {
    const [productData, setProductData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/single-product/${slug}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                if (responseData.success && responseData.data) {
                    setProductData(responseData.data);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        fetchProductData();
    }, [slug]);

    return (
        <div>
            {productData ? (
                <div>
                    <section>
                        <Container>
                            <ProductSlider productData={productData} />
                        </Container>
                    </section>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}

export default Productdetail;












// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container } from 'react-bootstrap';
// import "./Productdetail.css";
// import ProductImage from "../../assets/images/product11.png";
// import ProducImage2 from "../../assets/images/product6.png";
// import ProductSlider from "./ProductSlider";
// import Reviews from './Reviews';
// import Description from "./Description"

// const ProductDetail = () => {
//     // window.scroll(0, 0);
//     const products = [
//         {
//             name: "Gildan G185 Adult Heavy Blend 50/50 Hooded Sweatshirt",
//             inStock: true,
//             price: "$19.99",
//             image: ProductImage,
//             image1: ProducImage2,
//             videoId: null
//         },
//         {
//             name: "Gildan G185 Adult Heavy Blend 50/50 Hooded Sweatshirt",
//             inStock: false,
//             price: "$24.99",
//             image: ProductImage,
//             image1: ProducImage2,
//             videoId: null
//         },
//         {
//             name: "Gildan G185 Adult Heavy Blend 50/50 Hooded Sweatshirt",
//             inStock: false,
//             price: "$24.99",
//             image: ProductImage,
//             image1: ProducImage2,
//             videoId: null
//         },
//         {
//             name: "Gildan G185 Adult Heavy Blend 50/50 Hooded Sweatshirt",
//             inStock: false,
//             price: "$24.99",
//             image: ProductImage,
//             image1: ProducImage2,
//             videoId: null
//         },
//     ];

//     return (
//         <>
//             <section>
//                 <Container>
//                     <ProductSlider />
//                 </Container>
//             </section>
//             <section>
//                 <Description />
//             </section>
//             <section >
//                 <Container>
//                     <div className='row '>
//                         <div className='col-md-12 mt-5'>
//                             <div className='d-flex transfing'>
//                                 <div> <p className='transfer-review text-start thousands mx-5'>Thousands of 5 Star DTF Transfer Reviews</p></div>
//                                 <div> <span className='line-vertical'></span></div>
//                                 <div>
//                                     <p className='custom-review mx-3'>Real Customer Reviews</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row mt-5 mb-3">
//                         {products.map((product, index) => (
//                             <div key={index} className="col-md-3 mb-4">
//                                 <div className="card1">
//                                     <img
//                                         src={product.image}
//                                         alt='product'
//                                         onMouseOver={(e) => e.currentTarget.src = product.image1}
//                                         onMouseOut={(e) => e.currentTarget.src = product.image}
//                                     />
//                                 </div>
//                                 <div className='product-details1 mx-2'>
//                                     <div className='product-price mt-3'>
//                                         <h6 className='mx-2 price-range'> From {product.price}</h6>
//                                     </div>
//                                     <div className='icn-pro'>
//                                         <p className='text-left product-namesss '>{product.name}</p>
//                                     </div>
//                                     <button className="btn btn-primary hover-button order-now mt-2 mb-4">
//                                         <span className="btn-text">View Products</span>
//                                         <span className="btn-icon">&rarr;</span>
//                                     </button>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                     <Reviews />
//                 </Container>
//             </section>
//         </>
//     )
// }
// export default ProductDetail;

