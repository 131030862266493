import React, { useEffect, useState } from "react";
import "./Blog.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import PlaceholderImage from "../../assets/images/Group1000004028.png";
import axios from "axios";
import { Link } from "react-router-dom";

const Blog = () => {
  window.scroll(0, 0);
  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [blogs, setBlogs] = useState([]);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/category-with-blogs`
      );
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div>
      <section className="mt-5 pagesblog">
        <Container>
          <Row>
            <h1 className="blogs-transfer text-start">
              anythingApparel Transfers Blog
            </h1>
            <p className="text-start tutorials mt-3">
              Explore in-depth tutorials, expert tips, and insider insights to
              help elevate your DTF printing skills and help you choose the
              perfect blank canvases for your projects. From the latest trends
              in apparel customization to step-by-step guides on creating
              stunning, durable designs, anythingApparel Transfers is your go-to
              source for all things DTF.
            </p>
          </Row>

          <Row>
            {blogs.map((category) => (
              <div key={category.id}>
                <div className=" transfing d-flex transfers mt-5 tutorial maincls">
                  <div className="d-flex category-tut-videos">
                    <h1 className="transfer-review  dtf-text text-start mx-3">
                      {category.title}
                    </h1>
                    <div>
                      <p className="custom-review mx-3 left-b">
                        Everything DTF You Need to Know and Learn
                      </p>
                    </div>
                  </div>
                  <div className="category-tut">
                    <Link
                      to={`/blog-category/${category.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <button className="btn btn-primary hover-button button--outline read-more mt-3">
                        <span className="btn-text">Read more</span>
                        <span className="btn-icon">&rarr;</span>
                      </button>
                    </Link>
                  </div>
                </div>
                <Row>
                  {category.blogs.map((blog, index) => (
                    <div key={blog.slug} className="col-md-4  mb-5 mt-5 ">
                      <Link
                        className="text-black"
                        to={`/pages/blog-detail/${blog.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="blogs-data blog-img">
                          <img
                            className="zoom-out-image "
                            src={
                              imageError
                                ? PlaceholderImage
                                : blog.media.link
                                  ? `${basePath}${blog.media.link}`
                                  : PlaceholderImage
                            }
                            alt={`blog-${index}`}
                            onError={handleImageError}
                            style={{
                              transition: "opacity 0.5s ease-in-out",
                              backgroundColor: "#f2f2f2",
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              borderRadius: "20px",
                            }}
                          />
                        </div>
                        <div className="blogs-date">
                          <p className="text-start mt-3 px-3">
                            {blog.created_at}
                          </p>
                          <h3 className=" blogs-title text-start mt-2">
                            Title: {blog.title}
                          </h3>
                          <h5
                            className=" text-start mt-3 "
                            dangerouslySetInnerHTML={{
                              __html: blog.content.substring(0, 100) + "...",
                            }}
                          ></h5>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Row>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Blog;
