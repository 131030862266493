import React, { useEffect, useState } from "react";
import Youtube from "../../assets/images/youtube.png";
import PlaceholderImage from "../../assets/images/Group1000004028.png";
import axios from "axios";
import { Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const Tutorial = () => {

  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [blogs, setBlogs] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleImageError = () => {
    setImageError(true);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/videos-with-category`
      );

      setBlogs(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchBlogs();
  }, []);

  const playVideo = (blog) => {
    setSelectedVideo(blog);
  };

  const getVideoId = (url) => {
    const regex =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regex);
    return match ? match[2] : null;
  };

  return (
    <div className="tut mt-5">
      <section className="tutorial mt-5" style={{ minHeight: "300px" }}>
        <div className="container">
          {loading ? (

            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "300px" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className="row">
              <h5 className="text-start tut-head">Tutorial Videos</h5>
              <p className="press-ninja text-start">
                Learn how to press like a anything Apparel
              </p>

              <Row className="m-0 p-0">
                {blogs?.map((category) => (
                  <div key={category.id}>
                    <div className=" transfing d-flex transfers mt-5 tutorial maincls">
                      <div className="d-flex category-tut-videos">
                        <h1 className="transfer-review  dtf-text text-start mx-3">
                          {category.title}
                        </h1>
                        <div>
                          <p className="custom-review mx-3 left-b">
                            Everything DTF You Need to Know and Learn
                          </p>
                        </div>
                      </div>

                      <div className="category-tut">
                        <Link
                          to={`/videos-category/${category.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <button className="btn btn-primary hover-button button--outline read-more mt-3">
                            <span className="btn-text">Read more</span>
                            <span className="btn-icon">&rarr;</span>
                          </button>
                        </Link>
                      </div>
                    </div>

                    <Row>
                      {category.video.map((blog, index) => (
                        <div
                          key={blog.id}
                          className="col-md-3 mb-3 mt-3 tut-card"
                        >
                          <div className="tut-img position-relative">
                            {selectedVideo &&
                              selectedVideo.media_id === blog.media_id ? (
                              <div className="video-player">
                                {blog.type === "youtube" ? (
                                  <>
                                    <iframe
                                      width="100%"
                                      loading="lazy"
                                      src={`https://www.youtube.com/embed/${getVideoId(
                                        blog?.link
                                      )}`}
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerpolicy="strict-origin-when-cross-origin"
                                      allowfullscreen
                                    ></iframe>
                                  </>
                                ) : (
                                  <>
                                    <iframe loading="lazy" src={`https://player.vimeo.com/video/${blog.embeded_link}?h=5a5572cc05&autoplay=1&title=0&byline=0&portrait=0`} width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                  </>
                                )}
                              </div>
                            ) : (
                              <>
                                <img
                                  className="inner-img"
                                  src={
                                    imageError
                                      ? PlaceholderImage
                                      : blog?.media.link
                                        ? `${basePath}${blog?.media?.link}`
                                        : PlaceholderImage
                                  }
                                  alt={`blog-${index}`}
                                  onError={handleImageError}
                                  style={{
                                    transition: "opacity 0.5s ease-in-out",
                                    backgroundColor: "#f2f2f2",
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div
                                  className="youtube-img"
                                  onClick={() => playVideo(blog)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={Youtube} alt="youtube" />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="tut-content">
                            <Link
                              className="text-black"
                              to={`/pages/tutorial/${blog.slug}`}
                              style={{ textDecoration: "none" }}
                            >
                              <p className="tut-title mt-3">{blog.title}</p>
                              <p
                                className="tut-content mt-3"
                                dangerouslySetInnerHTML={{
                                  __html: blog.content.substring(0, 100) + "...",
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </Row>
                  </div>
                ))}
              </Row>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Tutorial;
