import React, { useState } from 'react';
import PlaceholderImage from "../../assets/images/tutorail.png"
import Youtube from "../../assets/images/youtube.png"
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Tutorial = ({ videos }) => {
  AOS.init();
  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [imageError, setImageError] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleImageError = () => {
    setImageError(true);
  };

  const playVideo = (video) => {
    setSelectedVideo(video);
  };

  const getVideoId = (url) => {
    const regex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regex);
    return match ? match[2] : null;
  }

  return (
    <div className="tut">
      <section className="tutorial pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-2">
              <h5 className="text-start tut-head m">Tutorial Videos</h5>
              <p className="press-ninja text-start pt-2">
                Learn how to press like a Ninja
              </p>
            </div>
            <div className='col-md-6 pb-3 pt-3 text-end tutorial-video-all-btn' >
              <Link to="/pages/tutorial">
                <button className="btn btn-primary hover-button visi-blog-btn view-all-videos">
                  <span className="btn-text">View all</span>
                  <span className="btn-icon">&rarr;</span>
                </button>
              </Link>
            </div>

            {Array.isArray(videos) &&
              videos?.map((video, index) => (
                <div className="col-md-4 mb-3 mt-3 tut-card" key={index} data-aos="fade-right">
                  <div className="tut-img position-relative">
                    {selectedVideo &&
                      selectedVideo.media_id === video.media_id ? (
                      <div className="video-player">
                        {video.type === "youtube" ? (
                          <>
                            <iframe
                              width="100%"
                              loading="lazy"
                              src={`https://www.youtube.com/embed/${getVideoId(
                                video?.link
                              )}`}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerpolicy="strict-origin-when-cross-origin"
                              allowfullscreen
                            ></iframe>
                          </>
                        ) : (
                          <>
                            <iframe loading="lazy" src={`https://player.vimeo.com/video/${video.embeded_link}?h=5a5572cc05&autoplay=1&title=0&byline=0&portrait=0`} width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <img
                          className="inner-img"
                          src={
                            imageError
                              ? PlaceholderImage
                              : video?.media?.link
                                ? `${basePath}${video?.media?.link}`
                                : PlaceholderImage
                          }
                          alt={`blog-${index}`}
                          onError={handleImageError}
                          style={{
                            transition: "opacity 0.5s ease-in-out",
                            backgroundColor: "#f2f2f2",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <div
                          className="youtube-img"
                          onClick={() => playVideo(video)}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={Youtube} alt="youtube" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="tut-content">
                    <Link to={`/pages/tutorial/${video.slug}`}>
                      <p className="tut-title mt-3">{video.title}</p>
                    </Link>
                    <p
                      className="tut-content mt-3 "
                      style={{ color: "rgb(55 52 52 / 88%) " }}
                      dangerouslySetInnerHTML={{
                        __html: video.content.substring(0, 100) + "...",
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tutorial;