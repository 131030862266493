import React from 'react'
import DeliveryButton from './DeliveryButton'

const OrderInput = () => {
    return (
        <div className='conatiner'>
            <div className='row'>
                <div className='text-start mt-3'>
                    <h5 >When will my order arrive?</h5>
                    <div className='d-flex gap-3'>
                        <input type="text" className="form-control1 me-1 " id="sizeInput1" placeholder="Enter Zip Code" />
                        <DeliveryButton />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderInput