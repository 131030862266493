import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import PlaceholderImage from "../../assets/images/Group1000004028.png";
import axios from "axios";

const BlogDetail = () => {
  window.scroll(0, 0);
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blog/${slug}`
        );
        setBlogData(response.data.data);

      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, [slug]);

  return (
    <div>
      <section>
        <Container>
          <Row>
            {blogData && (
              <div className="col-md-12">
                <div className="tradess mt-5">
                  <img
                    className="zoom-out-image"
                    src={
                      imageError
                        ? PlaceholderImage
                        : blogData.media?.link
                          ? `${basePath}${blogData.media?.link}`
                          : PlaceholderImage
                    }
                    onError={handleImageError}
                    style={{
                      transition: "opacity 0.5s ease-in-out",
                      backgroundColor: "#f2f2f2",
                      objectFit: "cover",
                      width: "100%",
                      borderRadius: "20px",
                      height: "500px",
                    }} alt=""
                  />
                </div>
                <h1 className="text-start mt-5 px-4">{blogData.title}</h1>
                <div
                  className="text-start mt-2 px-4"
                  dangerouslySetInnerHTML={{ __html: blogData.content }}
                ></div>
              </div>
            )}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default BlogDetail;
