import React from 'react';
import { Link } from 'react-router-dom';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductTitle = ({ productData }) => {
    console.log(productData, "title");
    const stars = Array.from({ length: 5 }, (_, index) => (
        <FontAwesomeIcon key={index} icon={faStar} style={{ marginRight: '5px' }} />
    ));

    return (
        <div>
            <div className='jersey text-start'>
                <h2>{productData.title}</h2>
            </div>
            <div className="icon-product-detailss mt-3 d-flex">
                <div className='text-start'>
                    {stars}
                </div> &nbsp; &nbsp;
                <div className=''>
                    <span style={{ color: "black", marginRight: '5px' }}>60 Reviews</span> &nbsp; &nbsp;
                </div>
                <div className=''>
                    <Link to="">See reviews summary</Link>
                </div>
            </div>
        </div>
    );
}

export default ProductTitle;
