import React from 'react';
import Robo from "../../assets/images/robo1.png";
import image1 from "../../assets/images/product1.png";
import image2 from "../../assets/images/product2.png";
import image3 from "../../assets/images/product3.png";
import image4 from "../../assets/images/product4.png";
import image5 from "../../assets/images/product5.png";
import image6 from "../../assets/images/product6.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Upload = () => {
    AOS.init();
    return (
        <div className='upload mt-5'>
            <section className='upload-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex transfing'>
                                <div> <p className='transfer-review text-start me-5'>Thousands of 5 Star DTF Transfer Reviews</p></div>
                                <div> <span className='line-vertical '></span></div>
                                <div>  <p className='custom-review mx-3 ps-2'>Real Customer Reviews</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='row mt-3'>
                        <div className='col-md-4' data-aos="fade-down">
                            <div className='robo-product position-relative '>
                                <div className='imges-robo '>
                                    <img src={Robo} alt='robo' />
                                </div>
                                <div className='content'>
                                    <p><b>Upload Your Designs and Place Order Online</b></p>
                                    <p>Use designs with unlimited colors, simple, or high detail artwork. Upload any design you want and we'll turn it into a high-quality direct to film transfer.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4' data-aos="fade-down">
                            <div className='robo-product position-relative '>
                                <div className='imges-robo'>
                                    <img src={Robo} alt='robo' />
                                </div>
                                <div className='content'>
                                    <p><b>Upload Your Designs and Place Order Online</b></p>
                                    <p>Use designs with unlimited colors, simple, or high detail artwork. Upload any design you want and we'll turn it into a high-quality direct to film transfer.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4' data-aos="fade-down">
                            <div className='robo-product position-relative'>
                                <div className='imges-robo'>
                                    <img src={Robo} alt='robo' />
                                </div>
                                <div className='content'>
                                    <p><b>Upload Your Designs and Place Order Online</b></p>
                                    <p>Use designs with unlimited colors, simple, or high detail artwork. Upload any design you want and we'll turn it into a high-quality direct to film transfer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-5'>
                <div className='container'>
                    <div className='row mt-3'>
                        <div className='col-md-12' data-aos="fade-up">
                            <div className='text-center' >
                                <p className='pritning'>Finally... Print Any Design, for Anyone, on Any <br /><span>Product with DTF Transfers.</span></p>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-2' data-aos="fade-up">
                        <div className='col-md-12'>
                            <div className='text-center'>
                                <p className='hight-quality'>Our High Quality DTF (Direct to Film) transfers allow anyone from small  to large shops, hobbyists, and brands <br />to print any design on any  product. There is virtually no limitation to what we can print whether  you need bright <br />white, solids, gradients, or fine lines!</p>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-2' data-aos="fade-up">
                        <div className='col-md-12' >
                            <div className='d-flex flex-wrap justify-content-center gap-5'>
                                <div className='imges'>
                                    <img src={image1} alt='robo' />
                                    <p className='cotton'>100% Cotton or 50/50 Blend</p>
                                </div>
                                <div className='imges'>
                                    <img src={image2} alt='robo' />
                                    <p className='cotton'>Spandex</p>
                                </div>
                                <div className='imges'>
                                    <img src={image3} alt='robo' />
                                    <p className='cotton' >Nylon & Polyester</p>
                                </div>
                                <div className='imges'>
                                    <img src={image4} alt='robo' />
                                    <p className='cotton '>Twill & Canvas</p>
                                </div>
                                <div className='imges'>
                                    <img src={image5} alt='robo' />
                                    <p className='cotton'>Denim</p>
                                </div>
                                <div className='imges'>
                                    <img src={image6} alt='robo' />
                                    <p className='cotton'>And more...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Upload;
