import React from 'react';
import FilterItem from './FilterItem';

const SideMenu = () => {
    const filters = [
        { title: 'Brand', items: ['Brand 1', 'Brand 2', 'Brand 3', 'Brand 4', 'Brand 5', 'Brand 6', 'Brand 7', 'Brand 8', 'Brand 9', 'Brand 10', 'Brand 11', 'Brand 12'] },
        { title: 'Type', items: ['Type 1', 'Type 2', 'Type 3', 'Type 4', 'Type 5', 'Type 6', 'Type 7', 'Type 8', 'Type 9', 'Type 10', 'Type 11'] },
        { title: 'Gender/Age', items: ['Men', 'Women', 'Kids'] },
        { title: 'Colors', items: ['Red', 'Blue', 'Green', 'Yellow', 'Black', 'White', 'Purple', 'Orange', 'Pink', 'Brown', 'Grey', 'Teal', 'Cyan', 'Magenta'] },
        { title: 'Features', items: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5', 'Feature 6', 'Feature 7', 'Feature 8', 'Feature 9', 'Feature 10'] },
        { title: 'Fabric', items: ['Fabric 1', 'Fabric 2', 'Fabric 3', 'Fabric 4', 'Fabric 5', 'Fabric 6', 'Fabric 7', 'Fabric 8', 'Fabric 9', 'Fabric 10', 'Fabric 11'] },
        { title: 'Sleeve Length', items: ['Short Sleeve', 'Long Sleeve', 'Sleeveless'] },
        { title: 'Cuffs', items: ['Cuff 1', 'Cuff 2', 'Cuff 3', 'Cuff 4', 'Cuff 5'] },
        { title: 'Weight', items: ['Lightweight', 'Midweight', 'Heavyweight'] },
        { title: 'Neckline', items: ['V-Neck', 'Crew Neck', 'Scoop Neck'] },
        { title: 'Waist', items: ['Low Waist', 'Mid Waist', 'High Waist'] },
        { title: 'Tag', items: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5', 'Tag 6', 'Tag 7', 'Tag 8'] },
        { title: 'Shirt Length', items: ['Short', 'Regular', 'Long'] },
        { title: 'Fit', items: ['Slim Fit', 'Regular Fit', 'Loose Fit'] },
    ];

    return (
        <div className='SideMenu'>
            <section className='side-menu'>
                <div className='container'>
                    <div className='row  side-bar-menu'>
                        {filters.map((filter, index) => (
                            <FilterItem key={index} title={filter.title} items={filter.items} />
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SideMenu;
