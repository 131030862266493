

import React, { useEffect, useState } from 'react';
import "./Forgot.css"
import { Row, Col, Card, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { isUndefined, isEmpty } from 'lodash';


const Forgot = () => {
 window.scroll(0, 0);
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState();
    let navigate = useNavigate()
    const validateLogin = () => {
        if (!isUndefined(email)) {
            if (isEmpty(email)) {
                setEmailError("Email is a required field")
            }
            else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                setEmailError("Please enter a valid email");
            } else {
                setEmailError("")
            }
        }
    }
    useEffect(() => {
        validateLogin();
    }, [email]);

    return (
        <div>
            <section className='forget-pswd' >
                <Container>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs={12} md={6} lg={5} sm={12}>
                            <form className='forgot-form text-center'>
                                <h2 className=' mt-5 customer' >Reset your password</h2>
                                <p>We will send you an email to reset your password.</p>
                                <div className=' mt-2'>
                                    <div className='email-label'>
                                        <label htmlFor="email">Email Name</label>
                                    </div>
                                    <input className='px-3' type="text" placeholder=' Email' onChange={(e) => setEmail(e.target.value)} value={email} />
                                    <span className="text-danger">{emailError}</span> <br />
                                </div>
                                <div className='d-flex forget-btns'>
                                    <button className='submit-forget-password text-capitalize rounded-0' variant="contained"
                                    >Submit</button>
                                    <button className='cancel-btn text-capitalize rounded-0' variant="contained"
                                    ><Link to="/login" className='text-cancel text-black'>Cancel</Link></button>
                                </div>
                                <div className='return mt-3'>
                                    <p > <Link className='forgetpwd' to="/login">Return to login page</Link>  </p>
                                </div>
                            </form>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
export default Forgot;
