import React, { useState, useEffect, useRef } from "react";
import "./Faq.css";
import Message from "../../assets/images/email1.png";
import Phone from "../../assets/images/phone1.png";
import { Container, Spinner } from "react-bootstrap";

const Faq = () => {
    const [data, setData] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const faqRef = useRef([]);

    useEffect(() => {
        window.scroll(0, 0);
        fetch(`${process.env.REACT_APP_API_URL}/faqs/all`)
            .then(response => response.json())
            .then(data => {
                setData(data.data);
                setSelectedArtwork(Array(data.data.length).fill(false));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const toggle = (index) => {
        setSelectedArtwork(prevState => {
            const newState = [...prevState];
            newState[index] = !prevState[index];
            return newState;
        });
    };

    const filteredData = data.filter(item =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="faq">
            <div className="faq" style={{ minHeight: "300px" }}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "300px" }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <div>
                        <section className="refund-policy1">
                            <h1 className="frequently text-white pt-5">Frequently Asked Questions</h1>
                            <div className="help-input pt-4">
                                <input
                                    className="search-faq-input"
                                    type="text"
                                    placeholder="What can we help you with?"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                            <Container>
                                <div className="row mt-5">
                                    <div className="col-md-12 message-image">
                                        <div className="">
                                            <div className="messagese">
                                                <img src={Message} alt="message" className="message-img" />
                                                <p className="text-start mx-3 text-white"><b>Support Email</b><br />service@anythingapparel.com</p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="messagese">
                                                <img src={Phone} alt="message" className="phone-img" />
                                                <p className="text-start mx-3 text-white"><b>Support Email</b><br />service@anythingapparel.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </section>
                        <section className="faq mt-5" ref={(el) => (faqRef.current[0] = el)}>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <p className="check-most">Check most frequently asked questions here. If you still need help then please contact us via phone at 888-356-3665, or email us at service@anythingapparel.com</p>
                                    <div className="col-md-8">
                                        <div className="mt-4">
                                            <div className="accord ">
                                                {filteredData.length > 0 ? (
                                                    filteredData.map((item, index) => (
                                                        <div key={index} className="faq-items mb-3 mt-4">
                                                            <div className="faq-title" onClick={() => toggle(index)}>
                                                                <div className="faq-ques">
                                                                    <h5 className="faq-question mt-2">{item.question}</h5>
                                                                </div>
                                                                <span className="plus-icons">{selectedArtwork[index] ? "-" : "+"}</span>
                                                            </div>
                                                            <div className={selectedArtwork[index] ? "content1 show" : "content1"}>
                                                                <p className="faq-answer text-start px-3" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="no-results">
                                                        <p ><b>No FAQs found.</b></p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Faq;
