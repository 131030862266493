import React, { useState } from 'react';

const FilterItem = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='text-start'>
            <h5 className='side-heading'>
                {title} {' '}
                <button className="filter-btn" onClick={handleToggle}>{isOpen ? '-' : '+'}</button>
            </h5>
            <div className={`filter-items ${isOpen && items.length > 0 ? 'scrollable' : ''}`}>
                {items.map((item, index) => (
                    <div key={index}>
                        <label>
                            <input
                                type='checkbox'
                                value={item}
                            />
                            <span className='category-data mx-3'>{item}</span>
                        </label>
                    </div>
                ))}
            </div>
            {title !== 'Fit' && <hr />}
        </div>
    );
};

export default FilterItem;
