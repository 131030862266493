import React from 'react';
import Cart from "../../assets/images/cart.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const AddToCart = () => {
    return (
        <div className='container'>
            <div className='row'>
                <button className='cart-btn w-100'>
                    <FontAwesomeIcon icon={faCartShopping} />
                    <span> Add To Cart</span></button>

            </div>
        </div>
    )
}

export default AddToCart