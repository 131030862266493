import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import PlaceholderImage from "../../assets/images/Group1000004028.png";

const BlogDetailCategory = () => {
  window.scroll(0, 0);
  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [imageError, setImageError] = useState(false);
  const { slug } = useParams();
  const [title, setTitle] = useState("");
  const [blogs, setBlogs] = useState([]);

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/all/blogs/category-wise/${slug}`
        );
        setTitle(response.data.data.title);
        setBlogs(response.data.data.blogs);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, [slug]);

  return (
    <div>
      <Container>
        <Row className="mt-5 mb-5 ">
          <div className=" transfing d-flex transfers mt-5">
            <div className="d-flex">
              <h1 className="transfer-review dtf-text text-start mx-5">
                {title}
              </h1>
              <div className="ml-auto hover-button-read"></div>
            </div>
            <div>
              <span className="line-vertical"></span>
            </div>
            <div>
              <p className="custom-review mx-3">
                Everything DTF You Need to Know and Learn
              </p>
            </div>
          </div>
        </Row>
        <Row>
          {blogs.map((blog, index) => (
            <div className="col-md-4 mb-5 ">
              <Link
                className="text-black"
                to={`/pages/blog-detail/${blog.slug}`}
                style={{ textDecoration: "none" }}
              >
                <div key={blog.slug}>
                  <div className="blogs-data">
                    <img
                      className="zoom-out-image  "
                      src={
                        imageError
                          ? PlaceholderImage
                          : blog.media.link
                            ? `${basePath}${blog.media.link}`
                            : PlaceholderImage
                      }
                      alt={`blog-${index}`}
                      onError={handleImageError}
                      style={{
                        transition: "opacity 0.5s ease-in-out",
                        backgroundColor: "#f2f2f2",
                        objectFit: "cover",
                        width: "100%",
                        borderRadius: "20px",
                        height: "250px",
                      }}
                    />
                    <div className="blogs-date">
                      <p className="text-start mt-3 px-3">{blog.created_at}</p>
                      <h3 className=" blogs-title text-start mt-2">
                        Title: {blog.title}
                      </h3>
                      <h5
                        className=" text-start mt-3 pagesblog"
                        dangerouslySetInnerHTML={{
                          __html: blog.content.substring(0, 100) + "...",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BlogDetailCategory;
