import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Navbar from "./components/Header/Navbar";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "../src/components/Home/Home";
import Template from "./components/Template/Template";
import Blank from "./components/Blank/Blank";
import Productdetail from "./components/Productdetail/Productdetail";
import Login from "./components/Login/Login";
import Forgot from "./components/Forget/Forgot";
import Register from "./components/Register/Register";
import Specials from "./components/Special/Specials";
import Quote from "./components/Quote/Quote";
import Faq from "./components/Faq/Faq";
import RefundPolicy from "./components/Refund/RefundPolicy";
import NotFound from "./components/NotFound/Notfound";
import Blogs from "./components/Blogs/Blog";
import TradeShow from "./components/TradeShow/TradeShow";
import TradeShowDetail from "./components/TradeShow/TradeShowDetail";
import Satisfaction from "./components/satisfaction/Satisfaction";
import NinjaRewards from "./components/NinjaRewards/NinjaRewards";
import Tutorial from "./components/Tutorial/Tutorial";
import TutorialDetail from "./components/Tutorial/TutorialDetail";
import BlogDetail from "./components/BlogDetail/BlogDetail";
import BlogDetailCategory from "./components/BlogDetailCategory/BlogDetailCategory";
import Videos from "./components/Videos/Videos";
import ContactUs from "./components/Contactus/Contactus";
import ContactStandard from "./components/Contactstandard/Contactstan";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<Forgot />} />
          <Route path="/register" element={<Register />} />
          <Route path="/products" element={<Blank />} />
          <Route path="/product-detail/:slug" element={<Productdetail />} />
          {/* <Route path="/special-products" element={<Specials />} />
             <Route path="/template" element={<Template />} />
           <Route path="/quote" element={<Quote />} /> 
             <Route path="/pages/trade-shows" element={<TradeShow />} />
          <Route path="/pages/trade-shows/id" element={<TradeShowDetail />} />
         <Route path="/pages/contact" element={< />} /> */}
          <Route path="/faq" element={<Faq />} />



          <Route path="*" element={<NotFound />} />
          <Route
            path="/pages/satisfaction-guarante"
            element={<Satisfaction />}
          />
          <Route
            path="/pages/anything-transfer-rewards"
            element={<NinjaRewards />}
          />
          <Route path="/pages/tutorial" element={<Tutorial />} />
          <Route path="/videos-category/:slug" element={<Videos />} />
          <Route path="/pages/tutorial/:slug" element={<TutorialDetail />} />

          <Route path="/pages/blog" element={<Blogs />} />
          <Route path="/pages/blog-detail/:slug" element={<BlogDetail />} />
          <Route path="/blog-category/:slug" element={<BlogDetailCategory />} />

          <Route path="/pages/contact" element={<ContactUs />} />
          <Route path="/pages/:slug" element={<RefundPolicy />} />
          <Route
            path="/pages/content-standards"
            element={<ContactStandard />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
