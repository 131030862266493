


import React, { useState } from "react";
import Reward from "../../assets/images/Ninja_Rewards_Redeem.webp"

const Earn = () => {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const earnData = [
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
            icon: "/images-down.png"
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
        {
            title: "1 point for every $1 spent",
            description: "Make a purchase",
            backgroundColor: "rgb(1, 47, 89)",
            iconColor: "rgb(66, 169, 222)",
        },
    ];

    return (
        <>

            <section className="earn">
                <div className="container">
                    <div className="earn-box">
                        <h1 className="text-center earn" >
                            Ways to Earn
                        </h1>
                        <div className="row text-center">
                            {earnData.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-sm-4 col-md-4 col-lg-4 col-12"
                                >
                                    <div className="earn-wrapper"
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}>
                                        <div
                                            className="earn-content-card"
                                            style={{ backgroundColor: hoveredIndex === index ? '#4285f4' : item.backgroundColor }}
                                        >
                                            {hoveredIndex === index ? (
                                                <div className="body-cccc">
                                                    <button className="signup-hover-effect ">sign up</button>
                                                    <h6 className="text-white">
                                                        Already have Account? <span>Login</span>
                                                    </h6>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="icon-earn"><img src="/images/twit.png" alt="" /> </div>
                                                    <div className="body-content">
                                                        <div
                                                            className="title1 text-center"
                                                            style={{
                                                                color: "rgb(255, 255, 255)",
                                                                fontSize: "18px",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            {item.title}
                                                        </div>
                                                        <div
                                                            className="p-des"
                                                            style={{
                                                                color: "rgba(255, 255, 255, 0.6)",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>{" "}
                </div>
            </section>

            <section className="reedom  mt-5" style={{ backgroundColor: "#f2f2f2" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-12">
                            <div className="reedom-box">
                                <h1 className="text-start px-5">How to redeem your points.</h1>
                                <p className="text-start px-5 mt-3">
                                    Simply add your items to cart and select the amount of points
                                    you want to apply to your purchase in check out. It's that
                                    simple
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-12"   >
                            <div className="img">
                                <img src={Reward} alt="" />
                            </div>
                        </div>
                    </div></div>
            </section>


        </>
    );
};

export default Earn;









