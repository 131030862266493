import React, { useState } from 'react';

const SelectedColor = ({ variations }) => {
    console.log(variations, "variations")
    const [selectedColor, setSelectedColor] = useState('red');
    const colors = ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'cyan', 'magenta', 'pink', 'brown', 'teal', 'navy', 'olive',];

    const handleColorSelect = (color) => {
        setSelectedColor(color);
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className="icon-product-det mt-3 d-flex mt-5">
                    <div className='text-start'>
                        <p>Selected Color</p>
                    </div>
                    <div className='d-flex boxes-colorss px-2'>
                        <p className='color' style={{ backgroundColor: selectedColor }}></p>
                    </div>
                    <div>
                        <p>Hthr sport royal</p>
                    </div>
                </div>

                <div className='d-flex boxes-color g-3'>
                    {colors.map((color, index) => (
                        <div key={index} className='color-container' onClick={() => handleColorSelect(color)}>
                            <div className={color === selectedColor ? 'background-border' : ''}>
                                <div className='color' style={{ backgroundColor: color }}>
                                    {color === selectedColor && <span className='tick text-white'>&#10003;</span>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default SelectedColor;
