import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import Video from "../../assets/images/homeUnrivaled.mp4";

const Review = ({ testimonials }) => {
    // console.log(testimonials, "testimonialstestimonials")

    return (
        <div className='review mt-5'>
            <section className='review-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex transfing'>
                                <div> <p className='transfer-review text-start thousands me-5'>Thousands of 5 Star DTF Transfer Reviews</p></div>
                                <div> <span className='line-vertical'></span></div>
                                <div>  <p className='custom-review mx-3 ps-2'>Real Customer Reviews</p></div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2' >
                        <Slider testimonials={testimonials} />
                    </div>

                </div>
            </section>


            <section className="printers mt-5">
                <div className="container">
                    <div className="fleet">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <video className="background-video" autoPlay loop muted>
                                        <source src={Video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <p className="quality text-start">We Understand Service, Quality, and Speed Matter Most</p>
                                    <p className="unrivaled text-start">An Unrivaled Fleet of 40+ DTF Printers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Review;
