import { faRotateRight, faSquareCheck, faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Shipping = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='d-flex gap-2 ' style={{ color: '#3e6166' }}>
                    <div className='col-md-4 d-flex'>
                        <FontAwesomeIcon icon={faTruck} /> &nbsp;
                        <p>  Free 2-Day Shipping</p>
                    </div>
                    <div className='col-md-4 d-flex'>
                        <FontAwesomeIcon icon={faSquareCheck} /> &nbsp;
                        <p>  No Minimums</p>
                    </div>
                    <div className='col-md-4 d-flex'>
                        <FontAwesomeIcon icon={faRotateRight} /> &nbsp;
                        <p>  Free 45-Day Returns</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shipping