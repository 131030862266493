import React from "react";
import "./satisfaction.css"
import { Link } from "react-router-dom";
const Satisfaction = () => {
     window.scroll(0, 0);
    return (
        <>
            <div className="satisfaction">
                <div className="container-fluid">
                    <section className="ContactStandard">
                        <div className="container">
                            <h1>100% Customer Satisfaction Guaranteed</h1>
                            <p>
                                If you don’t love it, please let us know{" "}
                                <b> within 45 days of receiving your products</b>, by phone,
                                email, or live chat. We will be unable to replace or refund
                                any orders brought to our attention after 45 days of confirmed
                                delivery by our carriers.
                            </p>
                            <p>
                                {" "}
                                Sometimes we will need your assistance to get the order right,
                                and might request a photo or further instructions before
                                reproducing an order. Just so we know how to make it right! We
                                will never ask you to return products before we reproduce a
                                damaged or misprinted order. Want to know more about how we
                                work to bring you great service, and guarantee your full
                                satisfaction with your order?
                            </p>
                        </div>
                    </section>
                    <div className="row pt-5">
                        <div className="col-md-6 col-sm-6 col-12 col-lg-6 col-xl-6">
                            <div className="form12">
                                <h5>
                                    Feel free to reach out to us with any questions you may
                                    have.
                                </h5>
                                <form action="">
                                    <div className="inputbox">
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="inputbox">
                                        <input type="text" placeholder="Email" />
                                    </div>
                                    <div className="inputbox">
                                        <input type="text" placeholder="Subject" />
                                    </div>
                                    <div className="inputbox">
                                        <textarea
                                            rows="4"
                                            cols="22"
                                            type="text"
                                            placeholder="Message "
                                        />
                                    </div>


                                    <div className='review-more-btn mt-5 mx-3 text-start px-4'>
                                        <button className="btn btn-primary satisfiction-btn hover-button button--outline2 mt-2">
                                            <span className="btn-text"> Submit</span>
                                            <span className="btn-icon">&rarr;</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-12 col-lg-3 col-xl-3">
                            {" "}
                            <h5 className="forq text-start">Contact Info</h5>
                            <div className="info1">
                                <p className="text-start">
                                    We're on standby 24/7 to answer any questions or concerns.
                                </p>
                                <p className="text-start mt-2">Find us on Live Chat</p>
                                <p className="text-start mt-2">
                                    Phone: <span className="number12"> 1-888-356-3665</span>
                                </p>
                                <p className="text-start mt-2 mb-4">
                                    Email:{" "}
                                    <span className="number12">
                                        {" "}
                                        service@anythingAppareltransfers.comz
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-12 col-lg-3 col-xl-3">
                            <h5 className="forq text-start">Helpful Links</h5>
                            <div className="info1">
                                <Link className="number12">
                                    {" "}
                                    <p className="text-start">100% Satisfaction Guarantee</p>
                                </Link>

                                <Link className="number12">
                                    {" "}
                                    <p className="text-start mt-3">Shipping Policy</p>
                                </Link>
                                <Link className="number12">
                                    {" "}
                                    <p className="text-start mt-3">Refund & Reprint Policy</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Satisfaction;
