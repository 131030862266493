import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import Header from "./Header";
import Logo from "../../assets/images/loggo.png";
import Mail from "../../assets/images/mail.svg";
import Phone from "../../assets/images/phone.svg";
import Cart from "../../assets/images/cart.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Navbar = () => {
  const [customPatches, setCustomPatches] = useState([]);
  const [uvsubmenu, setUvsubmenu] = useState([]);
  const [categories, setCategories] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [dtf, setDtf] = useState([]);
  const [userName, setUserName] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const [showTemplateMenu, setShowTemplateMenu] = useState(false);
  const [showDtfMenu, setShowDtfMenu] = useState(false);
  const [showUvMenu, setShowUvMenu] = useState(false);
  const [showPatchesMenu, setShowPatchesMenu] = useState(false);
  const [showSupportMenu, setShowSupportMenu] = useState(false);
  const [showOpenMenu, setShowOpenMenu] = useState(false);
  const [showCloseMenu, setShowCloseMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleViewAllClick = (slug) => {
    navigate(`/products`, { state: { category: slug } });
  };


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.name) {
      setUserName(user.name);
    }
  }, []);

  const toggleOpenMenu = () => {
    if (!showOpenMenu) {
      document.getElementById("mySidenav").style.width = "100%";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
    setShowOpenMenu(!showOpenMenu);
  };
  const toggleCloseMenu = () => {
    if (!showCloseMenu) {
      document.getElementById("mySidenav").style.width = "0";
    } else {
      document.getElementById("mySidenav").style.width = "100% ";
    }
    setShowCloseMenu(!showCloseMenu);
  };
  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  const handleCategoryClick = (slug) => {
    setShowSubMenu(false);
    navigate(`/products`, { state: { category: slug } });
  };
  const handleItemClick = (slug) => {
    setShowSubMenu(false);
    navigate(`/products`, { state: { category: slug } });
  };

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const handleSubMenuHover = () => {
    setShowSubMenu(true);
  };

  const handleSubMenuLeave = () => {
    setShowSubMenu(false);
  };
  const handleSubMenuClick = () => {
    if (!showSubMenu) {
      setShowSubMenu(true);
    }
  };
  const toggleHelpMenu = () => {
    setShowHelpMenu(!showHelpMenu);
  };
  const handleHelpMenuHover = () => {
    setShowHelpMenu(true);
  };

  const handleHelpMenuLeave = () => {
    setShowHelpMenu(false);
  };
  const handleMenuClick = () => {
    if (!showHelpMenu) {
      setShowHelpMenu(true);
    }
  };
  const toggleTemplateMenu = () => {
    setShowTemplateMenu(!showTemplateMenu);
  };
  const handleTemplateMenuHover = () => {
    setShowTemplateMenu(true);
  };

  const handleTemplateMenuLeave = () => {
    setShowTemplateMenu(false);
  };
  const handleTemplateMenuClick = () => {
    if (!showTemplateMenu) {
      setShowTemplateMenu(true);
    }
  };
  const toggleDtfMenu = () => {
    setShowDtfMenu(!showDtfMenu);
  };
  const handleDtfMenuHover = () => {
    setShowDtfMenu(true);
  };

  const handleDtfMenuLeave = () => {
    setShowDtfMenu(false);
  };
  const handleDtfMenuClick = () => {
    setShowDtfMenu(false);
  };

  const toggleUvMenu = () => {
    setShowUvMenu(!showUvMenu);
  };
  const handleUvMenuHover = () => {
    setShowUvMenu(true);
  };

  const handleUvMenuLeave = () => {
    setShowUvMenu(false);
  };
  const handleUvMenuClick = () => {
    setShowUvMenu(false);
  };

  const togglePatchesMenu = () => {
    setShowPatchesMenu(!showPatchesMenu);
  };
  const handlePatchesMenuHover = () => {
    setShowPatchesMenu(true);
  };

  const handlePatchesMenuLeave = () => {
    setShowPatchesMenu(false);
  };
  const handlePatchesMenuClick = () => {
    setShowPatchesMenu(false);
  };

  const toggleSupportMenu = () => {
    setShowSupportMenu(!showSupportMenu);
  };
  const handleSupportMenuHover = () => {
    setShowSupportMenu(true);
  };

  const handleSupportMenuLeave = () => {
    setShowSupportMenu(false);
  };
  const handleSupportMenuClick = () => {
    if (!showSupportMenu) {
      setShowSupportMenu(true);
    }
  };

  const apparelItems = [
    { label: "Contact Us", link: "#!" },
    { label: "Order Issue submission", link: "#!" },
    { label: "Blank appear Returns", link: "#!" },
    { label: "Submit Tax Exempt Form", link: "#!" },
  ];
  const helpcenter = [
    { label: "Ordering Questions", link: "#!" },
    { label: "Artwork & Sizing Questions", link: "#!" },
    { label: "Shipping Questions", link: "#!" },
    { label: "Tutorial Videos", link: "#!" },
    { label: "Tools & Tips", link: "#!" },
    { label: "Pressing Instructions", link: "#!" },
    { label: "View All", link: "#!" },
  ];

  const categories1 = [
    {
      title: "Blank T-Shirts",
      items: ["Cinco De Mayo", "Mother Day", "Spring", "Baseball", "Patriotic", "Cats", "Dogs", "Pre-Made UV DTF Strickers", "4 Free Neck Tag Templates ", "View All"
      ],
    },
  ];

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (showTemplateMenu && event.target.closest('.down-ico') === null) {
        setShowTemplateMenu(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showTemplateMenu]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/blank-apparel`);
        const categoriesData = response.data.data;
        if (Array.isArray(categoriesData)) {
          setCategories(categoriesData);
        } else {
          console.error('Expected an array but got:', categoriesData);
        }
      } catch (error) {
        console.error('Error fetching the data:', error);
      }
    };

    fetchData();
  }, []);

  const blankApparelCategory = categories.find(category => category.title === "Blank Apparel");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/product/category-wise/dtf-supply`)
      .then(response => response.json())
      .then(data => {
        if (data.success && Array.isArray(data.data)) {
          const dtfProducts = data.data.map(item => {
            const product = item.product;
            return {
              imageUrl: `https://buit.in/anything-dashbaord/storage/${product.media.link}`,
              label: product.title,
              productSlug: product.slug,
              slug: item.category.slug
            };
          });
          setDtf(dtfProducts);
        } else {
          console.error('API response is not in expected format:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/product/category-wise/dtf-transfers`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if (responseData.success && responseData.data) {
          setTemplates(responseData.data); // Assuming responseData.data is an array
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/product/category-wise/uv-dtf-3d-permastickers`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if (responseData.success && responseData.data) {
          setUvsubmenu(responseData.data);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/product/category-wise/custom-patches`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if (responseData.success && responseData.data) {
          setCustomPatches(responseData.data);

        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="main-top-bar">
        <div className="email fixed-header text-center"   >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="mail-data text-start">
                  <img src={Mail} alt="mail" />
                  <Link
                    href="mailto:support@anythingapparel.com"
                    className="text-white mailto:mx-3 mx-2"
                  >
                    care@anythingapparel.com
                  </Link>
                </div>
              </div>
              <div className="col-md-6 text-end">
                <div className="mail-data text-end">
                  <img src={Phone} alt="phone" />
                  <Link
                    href="mailto:care@anythingapparel.com"
                    className="text-white mailto:mx-3 mx-2"
                  >
                    (855) 927 - 7273
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="topbar mt-5">
        <div className="container-fluid">
          <div className="row align-items-center mx-2">
            <div className="col-md-4 ">
              <div className="nav-logo   ">
                <Link to="/">
                  <img src={Logo} className="img w-50 " alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-4  order-lg-0">
              <form className="d-flex search-input-wrapper" role="search">
                <div className="input-group">
                  <input
                    className="form-control rounded-0 mt-2 search-input"
                    type="search"
                    placeholder="Search Cars eg. Porsche"
                    aria-label="Search"
                  />
                  <span className="input-group-append">
                    <span className="search-icon-wrapper">
                      <span className="search-icon" />
                    </span>
                  </span>
                </div>
              </form>
            </div>
            <div className="col-md-4  d-flex justify-content-end align-items-center">
              <div className=" ">
                <p className=" chat">
                  <b>Live Chat 24/7</b> <br />
                  <b>Chat with a AT</b>
                </p>
              </div>
              <div className=" align-items-center px-3">
                {userName ? (
                  <p className=" chat">Hello, {userName}</p>
                ) : (
                  <p className=" chat">
                    Hello <br />
                    <Link
                      to="/Login"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      {" "}
                      Login{" "}
                    </Link>
                    /{" "}
                    <Link
                      to="/Register"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      Register
                    </Link>
                  </p>
                )}
              </div>

              <div className="pb-3">
                <span
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={openNav}
                >
                  <img src={Cart} alt="cart" />
                </span>
              </div>
              <div>
                <div id="myCart" className={`cart ${isOpen ? "open" : ""}`}>
                  <div className="cart-h">
                    <h5>SHOPPING CART (0)</h5>
                    <Link
                      id="myCart"
                      href="!#"
                      className="closebtn"
                      onClick={closeNav}
                    >
                      &times;
                    </Link>
                  </div>
                  <div className="emptycart">
                    <p>Your cart is currently empty.</p>
                    <div className="browsing">
                      <button className="btn-broser">Continue browsing</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav id="navbar-started" className="navbar navbar-expand-lg navbar-expand-md bg-body-tertiary">
        <div className="container-fluid ">
          <button
            className="navbar-toggler opennav"
            type="button"
            data-bs-toggle="collapse"
            onClick={toggleOpenMenu}
          >
            <span className="navbar-toggler-icon " />
          </button>
          <Header />
          <div
            className="  collapse navbar-collapse navbar-collapse1"
            id="navbarSupportedContent"
          >
            <ul className=" navbar-nav me-auto mb-2 mb-lg-0 mx-2">
              <li
                className="nav-item"
                onMouseEnter={handleDtfMenuHover}
                onMouseLeave={handleDtfMenuLeave}
                onClick={handleDtfMenuClick}
              >
                <Link
                  className="nav-link down-ico"
                  aria-current="page"
                  onClick={toggleDtfMenu}
                >
                  DTF Transfers &nbsp;
                  <img
                    src="/images/down-chevron.svg"
                    alt=""
                  />
                </Link>
                {showDtfMenu && (
                  <div className="productlist1" style={{ overflow: 'hidden' }}>
                    <div className="row pt-3 pb-3 text-align">
                      {templates.map((template, index) => (
                        <div key={index} className="col mx-auto mb-md-0 mb-4">
                          <Link
                            to={`/product-detail/${template.product.slug}`}
                            style={{ color: "black" }}>
                            <div className="template-img">
                              <img src={`${process.env.REACT_APP_IMAGE_URL}/${template.product.media.link}`} alt='dtf-product' />
                              <div className="tempcontent-dtf mt-3 pt-3 pb-3">
                                <b>{template.product.title}</b>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              <li
                className="nav-item "
                onMouseEnter={handleUvMenuHover}
                onMouseLeave={handleUvMenuLeave}
                onClick={handleUvMenuClick}
              >
                <Link
                  className="nav-link down-ico"
                  aria-current="page"
                  onClick={toggleUvMenu}
                >
                  UV DTF 3D PermaStickers™ &nbsp;
                  <img
                    src="/images/down-chevron.svg"
                    alt=""
                  />
                </Link>
                {showUvMenu && (
                  <div className="productlist2" style={{ overflow: 'hidden' }}>
                    <div className="row   pt-3 pb-3 text-align">
                      {uvsubmenu.map((template, index) => (
                        <div
                          key={index}
                          className={`col${index === 3 ? "-12" : ""
                            } mx-auto mb-md-0 mb-4 ${index === 3 ? "text-center" : ""
                            }`}
                        >
                          <Link
                            to={`/product-detail/${template.product.slug}`}
                            style={{ color: "black" }}>
                            <div className="template-img">
                              <img src={`${process.env.REACT_APP_IMAGE_URL}/${template.product.media.link}`} alt="UV-DTF-3D-PermaStickers" className="tempcontent-img" />

                              <div className="tempcontent-dtf mt-3 pt-3 pb-3"><b>{template.product.title}</b></div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              {blankApparelCategory && (
                <li
                  className="nav-item"
                  onMouseEnter={() => setShowSubMenu(true)}
                  onMouseLeave={() => setShowSubMenu(false)}
                >
                  <Link
                    to="/"
                    className="nav-link down-ico"
                    aria-current="page"
                    onClick={toggleSubMenu}
                  >
                    {blankApparelCategory.title} &nbsp;
                    <img
                      src="/images/down-chevron.svg"
                      style={{ width: '16px' }}
                      alt=""
                    />
                  </Link>
                  {showSubMenu && (
                    <div className="productlist">
                      <div className="container">
                        <div className="row pt-3 pb-3">
                          {Array.isArray(blankApparelCategory.under_child) && blankApparelCategory.under_child.length > 0 ? (
                            blankApparelCategory.under_child.map((category, index) => (
                              <div key={index} className="col-12 col-md-6 col-lg-3 mb-4 bordercls">
                                <ul>
                                  <h5 onClick={() => handleCategoryClick(category.slug)}>{category.title}</h5>
                                  {Array.isArray(category.child) && category.child.map((item, idx) => (
                                    <li className="help-center-list" key={idx}>
                                      <div className="text-reset" onClick={() => handleItemClick(item.slug)}>
                                        {item.title}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))
                          ) : (
                            <p>No categories available</p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              )}
              <li
                className="nav-item"
                onMouseEnter={handlePatchesMenuHover}
                onMouseLeave={handlePatchesMenuLeave}
                onClick={handlePatchesMenuClick}
              >
                <Link className="nav-link  down-ico" onClick={togglePatchesMenu}>
                  Custom Patches &nbsp;
                  <img
                    src="/images/down-chevron.svg"
                    alt=""
                  />
                </Link>
                {showPatchesMenu && (
                  <div className="productlist1 custom-patches-scroll" style={{ left: "300px" }}>
                    <div className="row pt-3 pb-3 text-align">
                      {customPatches.map((product, index) => (
                        <div
                          key={index}
                          className={`col${index === 12 ? "-12" : ""
                            } mx-auto mb-md-0 mb-4 ${index === 3 ? "text-center" : ""
                            }`}
                        >
                          <Link
                            to={`/product-detail/${product.product.slug}`}
                            style={{ color: "black" }}>
                            <div className="template-img custom-patch">
                              <img src={`${process.env.REACT_APP_IMAGE_URL}/${product.product.media.link}`} alt="UV-DTF-3D-PermaStickers" className="custom-patches" />
                              <div className="tempcontent-dtf mt-3 pt-3 pb-3"><b>{product.product.title}</b></div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              <li
                className="nav-item"
                onMouseEnter={handleTemplateMenuHover}
                onMouseLeave={handleTemplateMenuLeave}
                onClick={handleTemplateMenuClick}
              >
                <Link to="/products" className="nav-link down-ico" onClick={toggleTemplateMenu}>
                  DTF Supplies &nbsp;
                  <img src="/images/down-chevron.svg" alt="" />
                </Link>
                {showTemplateMenu && (
                  <div className="productlistsupply custom-patches-scroll" style={{ left: '200px' }}>
                    <div className="row text-align">
                      {dtf.map((template, index) => (
                        <div key={index} className="col-md-2">
                          <div className="templates-img">
                            <img src={template.imageUrl} alt={template.label} />
                            <div className="tempcontent-dtf pt-3"><Link
                              to={`/product-detail/${template.productSlug}`} style={{ color: "black" }} ><b>  {template.label}</b>
                            </Link> </div>
                          </div>
                        </div>
                      ))}
                      <div className="text-center col-md-12">
                        <div className="arrow-icons mt-2">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </div>
                      </div>
                      <div className="viewspplies mt-3" onClick={() => handleViewAllClick(dtf[0].slug)}>
                        <Link to="/products"  > <b>view all</b></Link>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <li
                className="nav-item"
                onMouseEnter={handleHelpMenuHover}
                onMouseLeave={handleHelpMenuLeave}
                onClick={handleMenuClick}
              >
                <Link className="nav-link down-ico" onClick={toggleHelpMenu}>
                  Design Library &nbsp;
                  <img
                    src="/images/down-chevron.svg"
                    alt="down-chevron.svg"
                  />
                </Link>
                {showHelpMenu && (
                  <div
                    className="productlist-category custom-patches-scroll"
                    style={{ left: "827px", right: "192px", width: "25%" }}
                  >
                    {categories1.map((category, index) => (
                      <div
                        key={index}
                        className="col-12 p-0 m-0"
                      >
                        <ul className="p-0 category-list" >
                          {category.items.map((item, idx) => (
                            <li key={idx} className="text-left category-items">
                              <Link to="#!" className="text-reset px-2">
                                {item}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </li>
              <li
                className="nav-item"
                onMouseEnter={handleSupportMenuHover}
                onMouseLeave={handleSupportMenuLeave}
                onClick={handleSupportMenuClick}
              >
                <Link className="nav-link down-ico" onClick={toggleSupportMenu}>
                  Support &nbsp;
                  <img
                    src="/images/down-chevron.svg"
                    alt=""
                  />
                </Link>
                {showSupportMenu && (
                  <div className="productlist custom-patches-scroll " style={{ width: "70%", left: "300px" }}>
                    <div className="row  ">
                      <div class="col-md-5 bordercls">
                        <div className="support-img"></div>
                      </div>
                      <div class="col-md-3  py-3">
                        <ul className="">
                          <h4 className="">Support</h4>
                          {apparelItems.map((item, index) => (
                            <li className="help-center-list" key={index}>
                              <Link to={item.link} className="text-reset">
                                {item.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <ul>
                          <h4 className="mt-3 ">Explore</h4>
                          {apparelItems.map((item, index) => (
                            <li className="help-center-list" key={index}>
                              <Link to={item.link} className="text-reset">
                                {item.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div class="col-md-4 py-3">
                        <ul className="help-center">
                          <h4 className="">Help Center</h4>
                          {helpcenter.map((item, index) => (
                            <li className="help-center-list" key={index}>
                              <Link to={item.link} className="text-reset">
                                {item.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </ul>
            <div className="text-center text-lg-end sample-text" style={{ color: "rgb(66 169 222)" }}>
              <Link to=""><b>Free Sample Pack</b></Link>
            </div>
          </div>
          <div className="sidebar1"></div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
