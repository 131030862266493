import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1
    }
};

const Slider = ({ testimonials }) => {
    AOS.init();
    return (
        <div className="parent" data-aos="fade-up">
            <Carousel
                responsive={responsive}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
            >
                {testimonials.map((testimonial, index) => {
                    const rating = testimonial.rating;
                    return (
                        <div className="slider" key={index}>
                            <h4 className="slider-heading">{testimonial.name}</h4>
                            <div className="icon-ratings">
                                {[...Array(5)].map((_, i) => (
                                    <FontAwesomeIcon
                                        key={i}
                                        icon={faStar}
                                        className={i < rating ? 'gold-star' : 'green-star'}
                                    />
                                ))}
                            </div>
                            <p className="slider-para mt-4">{testimonial.content.substring(0, 250)}</p>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default Slider;
