import { useState } from "react";

const Faq = ({ faqs }) => {
    // console.log(faqs, "faqsfaqs")
    const [selected, setSelected] = useState(null);

    const toggle = (index) => {
        if (selected === index) {
            return setSelected(null);
        }
        setSelected(index);
    };

    return (
        <div className='faq'>
            <section className='faq mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h1 className='head-faq text-start mt-3'>FAQ</h1>
                            <div className="mt-4">
                                <div className="accordion">
                                    {faqs.map((item, index) => (
                                        <div key={index} className="item">
                                            <div className="title" onClick={() => toggle(index)}>
                                                <span className="plus-icon">{selected === index ? "-" : "+"}</span>
                                                <div className="ques">
                                                    <h2 className="question mt-2 text-start px-3">{item.question}</h2>
                                                </div>
                                            </div>
                                            <div className={selected === index ? "content1 show" : "content1"}>
                                                <p
                                                    className='answer text-start px-4'
                                                    dangerouslySetInnerHTML={{ __html: item.answer }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Faq;
