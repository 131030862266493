import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Container } from "@material-ui/core";
import { Row } from "react-bootstrap";
import "./Tutorial.css";
import PlaceholderImage from "../../assets/images/Group1000004028.png";
import axios from "axios";
import { useParams } from "react-router-dom";

const TutorialDetail = () => {

  window.scroll(0, 0);
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  const basePath = process.env.REACT_APP_IMAGE_URL;
  const [showVideo, setShowVideo] = useState(true);
  const [faqData, setFaqData] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/video/${slug}`);
        setBlogData(response.data.data);
        setBlogData(response.data.data);
        setFaqData(response.data.data.faq);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, [slug]);
  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const videoId = blogData ? blogData.embeded_link : "";

  const backgroundImage =
    blogData && blogData.media
      ? `${basePath}${blogData.media.link}`.replace(/&quot;/g, "")
      : PlaceholderImage;

  const toggle = (index) => {
    setSelected(selected === index ? null : index);
  };

  const renderVideoPlayer = () => {
    if (blogData && blogData.type === "youtube") {
      return (
        <iframe
          title="YouTube Video"
          width="100%"
          height="500"
          src={`//www.youtube.com/embed/${videoId}?autoplay=1`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      );
    } else if (blogData && blogData.type === "vimeo") {
      return (
        <div className="video-wrapper">
          <iframe
            loading="lazy"
            src={`//player.vimeo.com/video/${videoId}?h=5a5572cc05&autoplay=1&title=0&byline=0&portrait=0`}
            width="100%"
            height="450"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      );
    } else if (blogData && blogData.type === "other") {
      return (
        <div className="video-wrapper">
          <iframe
            loading="lazy"
            src={blogData.embeded_link}
            width="100%"
            height="450"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
  };

  return (
    <div className="mt-5">
      <section className={`step-video ${showVideo ? "show-video" : ""}`}>
        <Container>
          <Row>
            <div className="col-md-12">
              {!showVideo && (
                <div
                  className="step-banner1 position-relative"
                  style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                  {!showVideo && (
                    <div className="play-icn" onClick={toggleVideo}>
                      <div className="icon-play1  tut-paly-icons position-absolute top-50 start-50 translate-middle">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {showVideo && (
                <div className="video-container">
                  <div className="video-overlay"></div>
                  {renderVideoPlayer()}
                </div>
              )}
            </div>
          </Row>
        </Container>
      </section>
      <section className="faq mt-5">
        <Container>
          <div className="row mt-5">
            <div className="col-md-12 text-start mt-4 mb-4">
              <h5 className="head-faq mt-5">FAQ</h5>
              <div className="mt-4">
                <div className="accordion mb-5">
                  {faqData?.map((item, index) => (
                    <div key={index} className="item">
                      <div className="title" onClick={() => toggle(index)}>
                        <div className="ques">
                          <h2 className="question mt-2 text-start">
                            {item.question}
                          </h2>
                        </div>
                        <span className="plus-icon">
                          {selected === index ? "-" : "+"}
                        </span>
                      </div>
                      <div
                        className={
                          selected === index ? "content1 show" : "content1"
                        }
                      >
                        <p
                          className="answer text-start p-0 mt-3 mb-2"
                          dangerouslySetInnerHTML={{
                            __html: item.answer.substring(0, 100) + "",
                          }}
                        >
                          { }
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default TutorialDetail;
